import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Table } from 'react-bootstrap';
import { useApiUrl } from 'views/examples/ApiUrlContext';
import { useAuth } from 'views/examples/AuthContext';
import "./suggestedjobs.css";
import { useNavigate } from 'react-router-dom';
import useUtil from "views/examples/Util"
import Topbar from "../examples/TopBar"

const Notification = () => {
  const { apiUrl } = useApiUrl();
  const { userData } = useAuth();
  const { instbasic } = useAuth();
  const { userLoginCode } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [requrtmrntDetails, setRequrtmrntDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !userData ||
      userData === undefined ||
      userData === '' ||
      (typeof userData === 'object' && Object.keys(userData).length === 0)
    ) {
      navigate('/auth/login');
    }
  }, [userData, navigate]);

  const fetchData = async () => {
    try {
      const bearerToken = userData.accessToken;
      const response = await fetch(`${apiUrl}/ialert/bycode/${userLoginCode.name}`,
        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json', // Adjust the content type if needed
          },
        })
      const data = await response.json();

      setNotifications(data);
      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.Exception === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.Exception === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }
    } catch (error) {

    }
  };


  const fetchJobapplicationbycode = async (selectedNotification) => {
    try {
      const bearerToken = userData.accessToken;
      const response = await fetch(
        `${apiUrl}/jobapplications/job/${selectedNotification.jobCode}`,
        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json', // Adjust the content type if needed
          },
        })
      const data = await response.json();

      setJobs(data);
      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.Exception === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.Exception === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }
    } catch (error) {

    }
  };

  const fetchRequtmentDetails = async (selectedNotification) => {
    try {
      const bearerToken = userData.accessToken;
      const response = await fetch(
        `${apiUrl}/jobpost/code/${selectedNotification.jobCode}`,
        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json', // Adjust the content type if needed
          },
        })
      const data = await response.json();
      setRequrtmrntDetails(data);
      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.Exception === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.Exception === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }
    } catch (error) {

    }
  };


  useEffect(() => {
    fetchData();
  }, []); // Include apiUrl and userData.login in the dependency array

  const handleViewMore = (notification) => {
    setSelectedNotification(notification);
    fetchRequtmentDetails(notification)
    fetchJobapplicationbycode(notification);

  };

  const handleCloseModal = () => {
    setSelectedNotification(null);

  };


  return (

    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh", margin: "5px" }}
    >
      <Topbar />
      <div>
        <div>
          {notifications.map((notification) => (
            <Card key={notification.id} className="shadow-lg mb-4">
              <Card.Body>
                {notification.ucode && (
                  <p>
                    <strong>User UID</strong> {notification.ucode}
                  </p>
                )}

                {notification.startDt && (
                  <p>
                    <strong>Date</strong> {notification.startDt}
                  </p>
                )}

                {notification.data && (
                  <p>
                    <strong>Information</strong> {notification.data}
                  </p>
                )}
                {notification.status && (
                  <p>
                    <strong>Status</strong> {notification.status}
                  </p>
                )}
                {/* Add more conditional fields as needed */}
                <Button variant="primary" onClick={() => handleViewMore(notification)}>
                  View Jobs
                </Button>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>
      {!!selectedNotification && (
  <div className="notification-modal-overlay">
    <div className="notification-modal-content">
      <div className="notification-modal-header">
        <h2 className="modal-title">Job Details</h2>
        <button className="cancel-button" onClick={handleCloseModal}>
          &#x2715;
        </button>
      </div>

      <div className="notification-modal-body">
        {requrtmrntDetails && (
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th
                  colSpan="4"
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    backgroundColor: "#dbd7d7",
                  }}
                >
                  Job Details
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "25%" }}>
                  <strong>Posted Date</strong>
                </td>
                <td style={{ width: "25%" }}>{requrtmrntDetails.schdate}</td>
                <td style={{ width: "25%" }}>
                  <strong>Type</strong>
                </td>
                <td style={{ width: "25%" }}>{requrtmrntDetails.typeVal}</td>
              </tr>
              <tr>
                <td style={{ width: "25%" }}>
                  <strong>Role</strong>
                </td>
                <td style={{ width: "25%" }}>{requrtmrntDetails.roleVal}</td>
                <td style={{ width: "25%" }}>
                  <strong>Experience</strong>
                </td>
                <td style={{ width: "25%" }}>{requrtmrntDetails.experience}</td>
              </tr>
              <tr>
                <td style={{ width: "25%" }}>
                  <strong>Minimum Salary</strong>
                </td>
                <td style={{ width: "25%" }}>{requrtmrntDetails.salaryFrom}</td>
                <td style={{ width: "25%" }}>
                  <strong>Maximum Salary</strong>
                </td>
                <td style={{ width: "25%" }}>{requrtmrntDetails.salaryTo}</td>
              </tr>
              <tr>
                <td style={{ width: "25%" }}>
                  <strong>Gender Preferred</strong>
                </td>
                <td style={{ width: "25%" }}>{requrtmrntDetails.gender}</td>
                <td style={{ width: "25%" }}>
                  <strong>Status</strong>
                </td>
                <td style={{ width: "25%" }}>{requrtmrntDetails.status}</td>
              </tr>
              {(requrtmrntDetails.subject1Val || requrtmrntDetails.subject2Val) && (
                <tr>
                  <td style={{ width: "25%" }}>
                    <strong>Expected Subjects</strong>
                  </td>
                  <td style={{ width: "25%" }}>
                    {requrtmrntDetails.subject1Val}
                    {requrtmrntDetails.subject1Val && requrtmrntDetails.subject2Val && ", "}
                    {requrtmrntDetails.subject2Val}
                  </td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                </tr>
              )}
              {(requrtmrntDetails.qual1Val || requrtmrntDetails.qual2Val) && (
                <tr>
                  <td style={{ width: "25%" }}>
                    <strong>Qualification1</strong>
                  </td>
                  <td style={{ width: "25%" }}>{requrtmrntDetails.qual1Val}</td>
                  <td style={{ width: "25%" }}>
                    <strong>Qualification2</strong>
                  </td>
                  <td style={{ width: "25%" }}>{requrtmrntDetails.qual2Val}</td>
                </tr>
              )}
              {requrtmrntDetails.qual3Val && (
                <tr>
                  <td style={{ width: "25%" }}>
                    <strong>Qualification3</strong>
                  </td>
                  <td style={{ width: "25%" }}>{requrtmrntDetails.qual3Val}</td>
                  <td style={{ width: "25%" }}></td>
                  <td style={{ width: "25%" }}></td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
        <div className="notification-button">
          <Button variant="danger" onClick={handleCloseModal}>
            Close
          </Button>
        </div>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default Notification;