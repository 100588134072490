import { createContext, useContext, useState, useEffect } from 'react';

const SharedvarsContext = createContext();

export const Sharedvars = ({ children }) => {
  // Try to get dropdowns data from localStorage on initial load
  const storedDropdowns = JSON.parse(localStorage.getItem('dropdowns')) || [];
  const [dropdowns, setDropdowns] = useState(storedDropdowns);

  useEffect(() => {
    // Save dropdowns data to localStorage whenever it changes
    localStorage.setItem('dropdowns', JSON.stringify(dropdowns));
  }, [dropdowns]);

  // Method to filter dropdown data based on type
  const getDropdownData = (type) => {
     const dd = dropdowns.filter(item => item.type === type);
     return dd;
  };

  return (
    <SharedvarsContext.Provider value={{ dropdowns, setDropdowns, getDropdownData }}>
      {children}
    </SharedvarsContext.Provider>
  );
};

export const useSharedvars = () => {
  const context = useContext(SharedvarsContext);
  if (!context) {
    throw new Error('useSharedvars must be used within a SharedvarsProvider');
  }
  return context;
};
