import React, { useState } from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { Modal, Button, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useApiUrl } from "views/examples/ApiUrlContext";
import { useAuth } from "views/examples/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import './SucessModal.css';
import "./suggestedjobs.css";


const ChangePasswordModal = ({ isOpen, toggle }) => {
    const { apiUrl } = useApiUrl();
    const { userData, userLoginName } = useAuth();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);

    // Error states for each field
    const [currentPasswordError, setCurrentPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');

    const handleInputChange = (e, setState, setErrorState) => {
        setState(e.target.value);
        setErrorState(''); // Clear the error message on input change
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleNewConfirmPasswordVisibility = () => {
        setShowNewConfirmPassword(!showNewConfirmPassword);
    };

    const handleChangePassword = async () => {
        let valid = true;

        // Reset error states
        setCurrentPasswordError('');
        setNewPasswordError('');
        setConfirmNewPasswordError('');

        if (!currentPassword) {
            setCurrentPasswordError('Current password is required.');
            valid = false;
        }
        if (!newPassword) {
            setNewPasswordError('New password is required.');
            valid = false;
        } else if (newPassword.length < 8 || !/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
            setNewPasswordError('New password must be at least 8 characters long and contain at least one special character.');
            valid = false;
        }
        if (!confirmNewPassword) {
            setConfirmNewPasswordError('Confirm new password is required.');
            valid = false;
        }
        if (newPassword !== confirmNewPassword) {
            setConfirmNewPasswordError('New password and confirm new password do not match.');
            valid = false;
        }
        if (currentPassword === newPassword) {
            setNewPasswordError('New password cannot be the same as the current password.');
            valid = false;
        }

        if (!valid) return;

        // Data to send in the request body
        const requestData = {
            password: currentPassword,
            newPassword: newPassword,
            type: 'i',
            name: userLoginName,
        };

        try {
            const bearerToken = userData.accessToken;
            const response = await axios.put(
                `${apiUrl}/auth/change`,
                requestData,
                {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            const data = response.data;
            if (data.cd === 0) {
                toast.success('Password changed successfully');
                resetFieldsAndCloseModal();
            } else if (data.cd === 1) {
                toast.error(data.description);
                setCurrentPasswordError(data.description);
            } else {
                toast.error('Failed to change password');
                resetFieldsAndCloseModal();
            }
        } catch (error) {
            toast.error('Error changing password. Please check your inputs and try again.');
            resetFieldsAndCloseModal();
        }
    };

    const resetFieldsAndCloseModal = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        setCurrentPasswordError('');
        setNewPasswordError('');
        setConfirmNewPasswordError('');
        toggle();
    };

    return (
        <>
            {isOpen && (
                <div className="changepwd-modal-overlay">
                    <div className="changepwd-modal-content">
                        <div className="changepwd-modal-header">
                            <h2 className="modal-title">Change Password</h2>
                            <button className="cancel-button" onClick={resetFieldsAndCloseModal}>
                                &#x2715;
                            </button>
                        </div>
                        <div className="changepwd-modal-body">
                            <FormGroup className="position-relative mb-3">
                                <Label for="currentPassword" className="form-label">
                                    Current Password <span className="required-asterisk">*</span>
                                </Label>
                                <div style={{ position: 'relative' }}>
                                    <Input
                                        type={showPassword ? 'text' : 'password'}
                                        className="form-control changepassword-bold-text"
                                        id="currentPassword"
                                        value={currentPassword}
                                        onChange={(e) => handleInputChange(e, setCurrentPassword, setCurrentPasswordError)}
                                        onCopy={(e) => e.preventDefault()}
                                        invalid={currentPasswordError !== ""}
                                    />
                                    <FontAwesomeIcon
                                        icon={showPassword ? faEyeSlash : faEye}
                                        onClick={togglePasswordVisibility}
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            right: "10px",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            zIndex: "1000",
                                            color: "grey",
                                        }}
                                    />
                                </div>
                                {currentPasswordError && (
                                    <FormFeedback style={{ marginTop: '0.5rem', display: 'block' }}>
                                        {currentPasswordError}
                                    </FormFeedback>
                                )}
                            </FormGroup>

                            <FormGroup className="position-relative mb-3">
                                <Label for="newPassword" className="form-label">
                                    New Password <span className="required-asterisk">*</span>
                                </Label>
                                <div style={{ position: 'relative' }}>
                                    <Input
                                        type={showNewPassword ? 'text' : 'password'}
                                        className="form-control changepassword-bold-text"
                                        id="newPassword"
                                        value={newPassword}
                                        onChange={(e) => handleInputChange(e, setNewPassword, setNewPasswordError)}
                                        onCopy={(e) => e.preventDefault()}
                                        invalid={newPasswordError !== ""}
                                    />
                                    <FontAwesomeIcon
                                        icon={showNewPassword ? faEyeSlash : faEye}
                                        onClick={toggleNewPasswordVisibility}
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            right: "10px",
                                            top: "50%", // Adjusts to vertically center the icon
                                            transform: "translateY(-50%)",
                                            zIndex: "1000",
                                            color: "grey",
                                        }}
                                    />
                                </div>
                                {newPasswordError && (
                                    <FormFeedback style={{ marginTop: '0.5rem', display: 'block' }}>
                                        {newPasswordError}
                                    </FormFeedback>
                                )}
                            </FormGroup>

                            <FormGroup className="position-relative mb-3">
                                <Label for="confirmNewPassword" className="form-label">
                                    Confirm New Password <span className="required-asterisk">*</span>
                                </Label>
                                <div style={{ position: 'relative' }}>
                                    <Input
                                        type={showNewConfirmPassword ? 'text' : 'password'}
                                        className="form-control changepassword-bold-text"
                                        id="confirmNewPassword"
                                        value={confirmNewPassword}
                                        onChange={(e) => handleInputChange(e, setConfirmNewPassword, setConfirmNewPasswordError)}
                                        onCopy={(e) => e.preventDefault()}
                                        invalid={confirmNewPasswordError !== ""}
                                    />
                                    <FontAwesomeIcon
                                        icon={showNewConfirmPassword ? faEyeSlash : faEye}
                                        onClick={toggleNewConfirmPasswordVisibility}
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            right: "10px",
                                            top: "50%", // Vertically centers the icon
                                            transform: "translateY(-50%)",
                                            zIndex: "1000",
                                            color: "grey",
                                        }}
                                    />
                                </div>
                                {confirmNewPasswordError && (
                                    <FormFeedback style={{ marginTop: '0.5rem', display: 'block' }}>
                                        {confirmNewPasswordError}
                                    </FormFeedback>
                                )}
                            </FormGroup>


                        </div>
                        <div className="changepwd-button">

                            <Button onClick={handleChangePassword} variant="primary">
                                Change Password
                            </Button>
                            <Button onClick={resetFieldsAndCloseModal} variant="danger">
                                Cancel
                            </Button>

                        </div>
                    </div>
                </div>

            )}

            <ToastContainer />
        </>
    );
};

export default ChangePasswordModal;
