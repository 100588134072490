
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

const Footer = () => {

  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div style={{ display: "flex" }} className="copyright text-center text-xl-left text-muted">
            © {new Date().getFullYear()}{" "}

            <a
              className="font-weight-bold ml-1"
              href={`${process.env.PUBLIC_URL}/TermsCondition.html`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Svasti Innovation Labs Pvt Ltd
            </a>

          </div>
        </Col>


      </Row>
    </footer>
  );
};

export default Footer;
