// AboutUs.js

import React, {useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "views/examples/AuthContext";
import { Row } from "react-bootstrap";
import "./landing.css";
import Topbar from "../examples/TopBar"

const AboutUs = () => {
  const { userData } = useAuth();
  const { instbasic } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !userData || 
      userData === undefined || 
      userData === '' || 
      (typeof userData === 'object' && Object.keys(userData).length === 0)
    ) {
      navigate('/auth/login'); 
    }
  }, [userData, navigate]);
  return (
    <>
      
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh",margin:"5px" }}
      >
      <Topbar />
        <Row className="mb-4">
          <div className="about-card">
           
            <h3 className="section-heading">India's First Network of Private School Teachers</h3>
            <p style={{ fontFamily: "Arial", textAlign: "left", fontSize: "17px", paddingLeft: "20px", }}>Welcome to Shikshak Bandhu, India's largest network that connects Teachers and Schools with more than 1 Lakh eligible teachers as members and 8000 schools spread in more than 20 states and territories nationwide.</p>

            <h4 className="section-heading">Vision</h4>
            <p style={{  fontFamily: "Arial", textAlign: "left", fontSize: "17px", paddingLeft: "20px" }}>To create opportunities for every student through his/her school to find suitable teachers. A school with sufficient number of Creative, Productive and Active teachers can be farm field of emerging hopeful citizens.</p>

            <h4 className="section-heading">Mission</h4>
            <p style={{ fontFamily: "Arial", textAlign: "left", fontSize: "17px", paddingLeft: "20px" }}>The mission of Shikshak Bandhu is simple as well as noble: Every private school teacher in India should find it easy to find his destination school. Similarly no school should compromise with the situation of not getting a suitable teacher. Also, helping the stakeholders should be at affordable charges unlike any other traditional ways of providing the manpower or job opportunities.</p>

            <h4 className="section-heading">Who are we?</h4>
            <p style={{ fontFamily: "Arial", textAlign: "left", fontSize: "17px", paddingLeft: "20px" }}>The idea of Shikshak Bandhu began in the year 2020 with a simple online form during Covid Pandemic as it was difficult to find suitable faculty required for our Client Schools. Today, Shikshak Bandhu  is equipped with a dedicated Website(for Institutions) and Mobile App (for teachers) to keep them on set any time to get connected in a completely self directed and hassle free automated software with big database at the background. It is run by a team of Teachers having decades of academic and administrative experience. The platform is very much tailor made to provide complete human resource accumulation related issues. For more information about our company.</p>
          </div>
        </Row>
        </div>
    </>
  );
};

export default AboutUs;

