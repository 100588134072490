import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import axios from 'axios';
import { useApiUrl } from "views/examples/ApiUrlContext";
import Select from 'react-select';

const AdminModal = ({ isOpen, toggle, setLoginInstitute }) => {
    const { apiUrl } = useApiUrl();
    const [institutes, setInstitutes] = useState([]);
    const [selectedInstitute, setSelectedInstitute] = useState(null);
    const [options, setOptions] = useState([]);

    // Function to fetch data from the API
    const fetchAllInstitutes = async () => {
        try {
            const response = await axios.get(`${apiUrl}/iBasic`);
            setInstitutes(response.data); // Update the state with fetched data
        } catch (error) {
          
        }
    };

    // Call the fetchAllInstitutes function when the component mounts
    useEffect(() => {
        if (isOpen) {
            fetchAllInstitutes();
        }
    }, [isOpen]);

    // Prepare options for react-select
    useEffect(() => {
        setOptions(institutes.map(institute => ({ value: institute.i_code, label: institute.name })));
    }, [institutes]);

    // Handle institute selection
    const handleSelectChange = (selectedOption) => {
        setSelectedInstitute(selectedOption);
    };

    // Handle form submission
    const handleSubmit = () => {
        if (selectedInstitute) {
            setLoginInstitute(selectedInstitute.value); // Pass the selected institute's i_code
        }
        toggle(); // Close the modal
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Select Institute</ModalHeader>
            <ModalBody>
                {/* Render the searchable dropdown */}
                <Select
                    options={options}
                    value={selectedInstitute}
                    onChange={handleSelectChange}
                    placeholder="Search for institutes..."
                />
            </ModalBody>
            <ModalFooter>
                {/* Add submit button */}
                <Button color="primary" onClick={handleSubmit}>Submit</Button>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default AdminModal;
