import React, { useEffect } from 'react';
import axios from 'axios';
import { useApiUrl } from "views/examples/ApiUrlContext";

const PaymentHandler = ({ amount,currency }) => {
    const { apiUrl } = useApiUrl();

    const handlePayment = async () => {
        try {
            const response = await axios.post(`${apiUrl}/plans/create-order`, {
                amount: amount, // Convert to paise
                currency: currency,
            });

            const options = {
                key: 'rzp_live_VVjK5bnqvY9xRF',
                amount: response.data.amount,
                currency: response.data.currency,
                order_id: response.data.id, 
                name: 'Your Company Name',
                description: 'Payment Description',
                handler: function (response) {
                    alert(`Payment ID: ${response.razorpay_payment_id}`);
                    alert(`Order ID: ${response.razorpay_order_id}`);
                    alert(`Signature: ${response.razorpay_signature}`);
                },
                prefill: {
                    name: 'Customer Name',
                    email: 'customer.email@example.com',
                    contact: '9999999999',
                },
                
                notes: {
                    address: 'Customer Address',
                },
                theme: {
                    color: '#3399cc',
                },
            };

            const rzp1 = new window.Razorpay(options);
            rzp1.open();

            rzp1.on('payment.failed', function (response) {
                alert(`Description: ${response.error.description}`);
            });
        } catch (error) {
            console.error('Error initiating payment', error);
        }
    };

    useEffect(() => {
        handlePayment();
    }, [apiUrl]);

    return (
        <div>
          
        </div>
    );
};

export default PaymentHandler;
