// ConfigModal.js
import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Input, Button } from "reactstrap";
import { useApiUrl } from "views/examples/ApiUrlContext";
import { localUrl } from '../examples/Constant';

const ConfigModal = ({ isOpen, toggle, onSave, initialApiUrl }) => {
  const { apiUrl: contextApiUrl } = useApiUrl();
  

  const [apiUrl, setApiUrl] = useState(
    initialApiUrl || localUrl
  );

  const handleSave = () => {
    onSave(apiUrl);
    toggle();
  };

  const handleReset = () => {
    setApiUrl("");
  };

  useEffect(() => {
    // Set the API URL to the context value when the modal is opened
    if (isOpen) {
      setApiUrl(contextApiUrl || "");
    }
  }, [isOpen, contextApiUrl]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="font-weight-bold display-10">
        Configuration
      </ModalHeader>

      <ModalBody>
        {/* API URL Textfield */}
        <div className="mb-3">
          <label htmlFor="apiUrl" className="font-weight-bold">
            API URL
          </label>
          <Input
            type="text"
            id="apiUrl"
            placeholder="Enter API URL - Eg: http://13.60.40.180:8095"
            value={apiUrl}
            onChange={(e) => setApiUrl(e.target.value)}
          />
        </div>

        {/* Save and Reset Buttons */}
        <div className="text-right">
          <Button color="success" className="mr-2" onClick={handleSave}>
            Save
          </Button>
          <Button color="danger" onClick={handleReset}>
            Reset
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfigModal;
