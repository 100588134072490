import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const Header = () => {
  return (
    <>
      <div className="pb-0 pt-0"></div>
    </>
  );
};

export default Header;
