import React, { useState, useEffect } from 'react';
import jsPDF from "jspdf";
import { Container, Button, Table } from 'react-bootstrap';
import { Tabs, Tab } from '@mui/material';
import { useApiUrl } from 'views/examples/ApiUrlContext';
import { useAuth } from 'views/examples/AuthContext';
import useUtil from "views/examples/Util"
import axios from 'axios';
import "./suggestedjobs.css";
import { styled } from '@mui/system';
import { baseUrl } from '../examples/Constant';
import { useNavigate } from 'react-router-dom';
import MySnackbar from 'views/examples/Snackbar'
import Topbar from "../examples/TopBar"

const Suggestedcandidate = () => {
    const { apiUrl } = useApiUrl();
    const { userData } = useAuth();
    const { userLoginCode } = useAuth();
    const { instbasic } = useAuth();
    const [activeTab, setActiveTab] = useState(0);
    const [Suggestedjobs, setSuggesstedJobs] = useState([]);
    const [selecteducode, setSelecteducode] = useState(null);
    const [candidatedetails, setCandidateDetails] = useState(null);
    const [additionalDetails, setAdditionalDetails] = useState(null);
    const [updatedStatus, setUpdatedStatus] = useState('');
    const [basicinstitutedata, setBasicinstitutedata] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarBgColor, setSnackbarBgColor] = useState('');
    const [isSuccess, setIsSuccess] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (
            !userData ||
            userData === undefined ||
            userData === '' ||
            (typeof userData === 'object' && Object.keys(userData).length === 0)
        ) {
            navigate('/auth/login');
        }
    }, [userData, navigate]);


    const fetchData = async () => {
        try {
            const bearerToken = userData.accessToken;
            const response = await fetch(`${apiUrl}/jobpost/jobs/${userLoginCode.name}`,
                {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                        'Content-Type': 'application/json',
                    },
                })
            const data = await response.json();

            setSuggesstedJobs(data);
            if (response && (response.status === 403 || response.status === 400)) {
                const headers = response.headers;
                if (headers && headers.Exception === 'SignatureException') {
                    await useUtil.logout();
                    // clear shared Pref & perform logout
                } else if (headers && headers.Exception === 'ExpiredJwtException') {
                    bearerToken = await useUtil.refreshToken();
                    // Use the newBearerToken as needed
                }
            }
        } catch (error) {

        }
    };
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const fetchAdditionalDetails = async (selectedJob) => {
        try {
            const bearerToken = userData.accessToken;
            const response = await fetch(`${apiUrl}/jobpost/job/${selectedJob.jCode}/${selectedJob.uCode}`,
                {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                        'Content-Type': 'application/json', // Adjust the content type if needed
                    },
                })
            const data = await response.json();
            setAdditionalDetails(data);
            if (response && (response.status === 403 || response.status === 400)) {
                const headers = response.headers;
                if (headers && headers.Exception === 'SignatureException') {
                    await useUtil.logout();
                    // clear shared Pref & perform logout
                } else if (headers && headers.Exception === 'ExpiredJwtException') {
                    bearerToken = await useUtil.refreshToken();
                    // Use the newBearerToken as needed
                }
            }
        } catch (error) {

        }
    };

    const fetchCandidateDetails = async (selectedJob) => {
        try {
            const bearerToken = userData.accessToken;
            const response = await fetch(`${apiUrl}/ubasic/code/${selectedJob.uCode}`,
                {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                        'Content-Type': 'application/json', // Adjust the content type if needed
                    },
                })
            const data = await response.json();
            setCandidateDetails(data);
            if (response && (response.status === 403 || response.status === 400)) {
                const headers = response.headers;
                if (headers && headers.Exception === 'SignatureException') {
                    await useUtil.logout();
                    // clear shared Pref & perform logout
                } else if (headers && headers.Exception === 'ExpiredJwtException') {
                    bearerToken = await useUtil.refreshToken();
                    // Use the newBearerToken as needed
                }
            }
        } catch (error) {

        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        fetchInstituteData();
    }, [apiUrl, userData]);

    const fetchInstituteData = async () => {
        try {
            const bearerToken = userData.accessToken;
            const response = await fetch(`${apiUrl}/iBasic/code/${userLoginCode.name}`,
                {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                        'Content-Type': 'application/json', // Adjust the content type if needed
                    },
                })
            const data = await response.json();

            setBasicinstitutedata(data);
            if (response && (response.status === 403 || response.status === 400)) {
                const headers = response.headers;
                if (headers && headers.Exception === 'SignatureException') {
                    await useUtil.logout();
                    // clear shared Pref & perform logout
                } else if (headers && headers.Exception === 'ExpiredJwtException') {
                    bearerToken = await useUtil.refreshToken();
                    // Use the newBearerToken as needed
                }
            }

        } catch (error) {

        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
      };

    const handleViewMore = (job) => {
        setSelecteducode(job);
        fetchAdditionalDetails(job);
        fetchCandidateDetails(job);
        setUpdatedStatus(job.status);
    };

    const handleCloseModal = () => {
        setSelecteducode(null);
        setUpdatedStatus('');
        setActiveTab(0);
    };


    const generateAppointmentLetter = () => {

        if (candidatedetails && basicinstitutedata) {
            const pdf = new jsPDF();
            const pdfWidth = pdf.internal.pageSize.width;
            const margin = 15; // Margin from the edge of the page
            const contentWidth = pdfWidth - 2 * margin;
            // Set Fonts and Sizes
            pdf.setFont("times", "normal");
            pdf.setFontSize(12);

            // Get Current Date
            const currentDate = new Date();
            const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
            // Trust Text
            pdf.setFont("times", "bold");
            pdf.setFontSize(16);
            const trustText = `${basicinstitutedata.trust}`;
            const trustTextWidth = pdf.getStringUnitWidth(trustText) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
            const startX = (pdfWidth - trustTextWidth) / 2;
            pdf.text(trustText, startX, 20);

            // District and State
            pdf.setFont("times", "normal");
            pdf.setFontSize(12);
            const districtStateText = `Dist: ${basicinstitutedata.distVal}, State: ${basicinstitutedata.state}`;
            pdf.text(districtStateText, margin, 30);

            // Affiliation Number
            pdf.setFont("times", "bold");
            const afflNoText = `AFFILIATION NO - ${basicinstitutedata.afflNo}`;
            const afflNoWidth = pdf.getStringUnitWidth(afflNoText) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
            const afflNoX = (pdfWidth - afflNoWidth) / 2;
            pdf.text(afflNoText, afflNoX, 40);

            // Line Separator
            pdf.setDrawColor(0);
            pdf.setLineWidth(0.5);
            pdf.line(margin, 35, pdfWidth - margin, 35);

            // Appointment Letter Heading
            pdf.setFontSize(16).setFont(undefined, "bold");
            pdf.text("LETTER OF APPOINTMENT", pdfWidth / 2, 55, { align: "center" });

            // Candidate Details
            pdf.setFontSize(12).setFont(undefined, "normal");
            pdf.text(`${candidatedetails.name}`, margin, 60);
            pdf.text(`${candidatedetails.addressLine1}, ${candidatedetails.city},${candidatedetails.district}`, margin, 70);


            // Appointment Details
            pdf.setFontSize(12).setFont(undefined, "normal");
            pdf.text(
                `With reference to your application dated ${formattedDate} and interview dated ${formattedDate}, we have pleasure in welcoming you to the Bill Memorial Rotary English Medium CBSE School Mudhol family, while appointing you as TGT English & Geography Teacher in Bill Memorial Rotary English Medium CBSE School Mudhol, subject to your signing and returning one copy of the appointment order, as a token of accepting the following terms and conditions:`,
                margin,
                120,
                { maxWidth: contentWidth, lineHeight: 1.5 }
            );

            const termsAndConditionsPage1 = `
                1. Your date of appointment is effective from the date of joining which shall be immediate.
                2. You will be placed on probation for 11 months.
                3. During the period of probation, if your performance and conduct are found not keeping up with the organization’s rules and regulations you will be terminated without assigning any reason.
                4. Compensation: You would be placed in the consolidated pay scale of Rs.30,000/- per month.
                5. Retirement: The retirement age is 60 years.
                6. Responsibilities:
                    - Assessing, recording, and reporting on the development and progress of students. Be proactively involved in teaching students.
                    - Timely correction and marking of assignment works carried by the students in class and elsewhere.
                    - Motivating, facilitating, teaching according to the pupil’s education.
                    - Prepare materials and activities.
                    - Identify students with special requirements and create individualized plans.
                    - Determine exam and assignment grades.
                    - Research new teaching methods.
            `;

            const termsAndConditionsPage2 = `
                7. Leave Policy: You shall be eligible for such leave as is admissible under the Leave policy of the institution in force from time to time.
                8. Conflict of Interest: You are required to engage yourself exclusively in the work assigned by the institution and shall not take up any other part-time or full-time employment with any other organization which may directly or indirectly affect the interests of the institution.
                9. Confidentiality: As a member of the institution, you may gain access to such information that may be considered “confidential” by the institution. Therefore, you shall not divulge any confidential information to anyone outside the institution or to anyone inside who is not entitled to such information.
                10. Notice period: In the event of you deciding to part ways from Bill Memorial Rotary English Medium CBSE School Mudhol or vice versa, three months' notice on either side is essential. The institution reserves the right to pay or recover the salary in lieu of the notice period. A minimum of 2 months period is observed as the notice period in the event of you deciding to part ways or vice versa.
                11. On Separation: On acceptance of the separation notice, you shall ensure to handover all the materials of the institution in your possession that includes library books, presentation materials, data, literature, drawings, and documents belonging to the organization. You shall not make or retain any copies of these items.
                Please confirm that above terms are acceptable to you, and that you accept the Appointment by signing a copy of this letter of appointment.
                We look forward to your contribution to the growth of this institution and a successful career with us.
    
                President
                Modern Education Society Mudhol
    
                I agree to accept employment on the terms and conditions mentioned in the above letter.
                Name:                                                            Date:
                Signature:                                                       Place:
            `;

            pdf.text(termsAndConditionsPage1, margin, 160, { maxWidth: contentWidth });
            pdf.addPage();
            pdf.text(termsAndConditionsPage2, margin, 20, { maxWidth: contentWidth });

            // Open PDF in new window
            const pdfData = pdf.output("datauristring");
            const newWindow = window.open();
            newWindow.document.write(
                `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
            );
        } else {
            setSnackbarMessage("Please select the employee");
            setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
		
        }
    };


    const handleSendIntrest = async () => {
        try {
            const bearerToken = userData.accessToken;
            const selectedJob = Suggestedjobs.find(job => job.uCode === selecteducode.uCode);
            const selectedJobcode = Suggestedjobs.find(job => job.jCode === selecteducode.jCode);
            const currentDate = new Date();
            const sixMonthsLater = new Date();
            sixMonthsLater.setMonth(currentDate.getMonth() + 6);
            const response = await axios.post(
                `${apiUrl}/ualert`,
                {
                    jobCode: selectedJobcode.jCode,
                    ucode: selectedJob.uCode,
                    type: 'From me',
                    data: 'Interest shown on your profile. We will keep you posted...',
                    startDt: currentDate.toISOString(),
                    endDt: sixMonthsLater.toISOString(),

                },
                {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status == 201) {
                setSnackbarMessage('Intrest Sent to Specific user.')
                setSnackbarBgColor('green');
                setIsSuccess(true);
                setSnackbarOpen(true);
                fetchData();
                handleCloseModal();
            } else {
                setSnackbarMessage('Error Sending Intrest to Specific User.')
                setSnackbarBgColor('red');
                setIsSuccess(false);
                setSnackbarOpen(true);
                handleCloseModal();
            }
        } catch (error) {
            setSnackbarMessage('Error Sending Intrest to Specific User.')
            setSnackbarBgColor('red');
            setIsSuccess(false);
            setSnackbarOpen(true);
            handleCloseModal();
        }
    };

    const CustomTabs = styled(Tabs)({
        backgroundColor: 'teal',
        borderRadius: '8px',
        padding: '5px',
        border: '2px solid teal',
    });

    const CustomTab = styled(Tab)({
        color: '#fff',
        '&.Mui-selected': {
            backgroundColor: 'black', // Set active tab background to black
            color: '#fff', // Keep the text color white for contrast
            borderRadius: '8px',
        },
        '&:not(.Mui-selected)': {
            marginRight: '4px',
        },
        textTransform: 'none',
    });


    return (
        <>

            <div
                style={{ display: "flex", flexDirection: "column", minHeight: "100vh", }}
            >
                <Topbar />
                <div>
                    <div
                        style={{ padding: "10px" }}
                    >
                        <div style={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)", padding: "10px", }}>

                            <div style={{ display: "flex" }}>

                                <div style={{ flex: 1, }}>
                                    <div
                                        style={{

                                            padding: 10,
                                        }}
                                    >
                                        <div>
                                            {Suggestedjobs.map((job) => (
                                                <Table key={job.id} striped bordered hover className='table table-bordered-black'>
                                                    <tbody>
                                                        {job.jCode && (
                                                            <tr>
                                                                <td style={{ width: '20%' }}><strong>Job Code</strong></td>
                                                                <td style={{ width: '20%' }}>{job.jCode}</td>
                                                                <td style={{ width: '20%' }}><strong>Job Type</strong></td>
                                                                <td style={{ width: '20%' }}>{job.typeVal}</td>
                                                            </tr>
                                                        )}
                                                        {job.uCode && (
                                                            <tr>
                                                                <td style={{ width: '20%' }}><strong>User Code</strong></td>
                                                                <td style={{ width: '20%' }}>{job.uCode}</td>
                                                                <td style={{ width: '20%' }}><strong>Name</strong></td>
                                                                <td style={{ width: '25%' }}>{job.name}</td>
                                                                <td style={{ textAlign: 'center', width: '15%' }} rowSpan={5} className='align-middle'>
                                                                    <Button variant="primary" onClick={() => handleViewMore(job)}>View More</Button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {job.dob && (
                                                            <tr>
                                                                <td style={{ width: '20%' }}><strong>Date of Birth</strong></td>
                                                                <td style={{ width: '20%' }}>{job.dob}</td>
                                                                <td style={{ width: '20%' }}><strong>Gender Preferred</strong></td>
                                                                <td style={{ width: '25%' }}>{job.gender}</td>
                                                            </tr>
                                                        )}
                                                        {job.religion && (
                                                            <tr>
                                                                <td style={{ width: '20%' }}><strong>Religion</strong></td>
                                                                <td style={{ width: '20%' }}>{job.religion}</td>
                                                                <td style={{ width: '20%' }}><strong>Current City</strong></td>
                                                                <td style={{ width: '25%' }}>{job.city}</td>
                                                            </tr>
                                                        )}
                                                        {job.curroleVal && (
                                                            <tr>
                                                                <td style={{ width: '20%' }}><strong>Current Role</strong></td>
                                                                <td style={{ width: '20%' }}>{job.curroleVal}</td>
                                                                <td style={{ width: '20%' }}><strong>Highest Qualification</strong></td>
                                                                <td style={{ width: '25%' }}>{job.highestqlfVal}</td>
                                                            </tr>
                                                        )}
                                                        {job.totalexp && (
                                                            <tr>
                                                                <td style={{ width: '20%' }}><strong>Total Experience</strong></td>
                                                                <td style={{ width: '20%' }}>{job.totalexp}</td>
                                                                <td style={{ width: '20%' }}><strong>Current Salary</strong></td>
                                                                <td style={{ width: '25%' }}>{job.cursalary}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!!selecteducode && (
                <div className="job-modal-overlay">
                    <div className="job-modal-content">
                        <div className="job-modal-header">
                            <h2 className="modal-title">Job Suggestion</h2>
                            <button className="cancel-button" onClick={handleCloseModal}>
                                &#x2715;
                            </button>
                        </div>

                        <div className="job-modal-body">
                            <CustomTabs
                                value={activeTab}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="job suggestion tabs"
                            >
                                <CustomTab label="Job Posted" />
                                <CustomTab label="User Basic" />
                                <CustomTab label="User Preference Basic" />
                                <CustomTab label="User Preferences - Locations" />
                                <CustomTab label="User Preferences - Others" />
                                <CustomTab label="Language Proficiency" />
                                <CustomTab label="Educational Details" />
                                <CustomTab label="Professional Details" />
                            </CustomTabs>
                            {/* Tab Content */}
                            {activeTab === 0 && additionalDetails?.jobPost && (
                                <div>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan="6" style={{ textAlign: 'center', fontSize: '18px', backgroundColor: '#dbd7d7' }}>
                                                    Job Posted
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* Row 1 */}
                                            <tr>
                                                <td style={{ width: '16.67%' }}><strong>Posted Date</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.jobPost.schdate}</td>
                                                <td style={{ width: '16.67%' }}><strong>Type</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.jobPost.typeVal}</td>
                                                <td style={{ width: '16.67%' }}><strong>Role</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.jobPost.roleVal}</td>
                                            </tr>

                                            {/* Row 2 */}
                                            <tr>
                                                <td style={{ width: '16.67%' }}><strong>Subject1</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.jobPost.subject1Val}</td>
                                                {additionalDetails.jobPost.subject2Val && (
                                                    <>
                                                        <td style={{ width: '16.67%' }}><strong>Subject2</strong></td>
                                                        <td style={{ width: '16.67%' }}>{additionalDetails.jobPost.subject2Val}</td>
                                                    </>
                                                )}
                                                <td style={{ width: '16.67%' }}><strong>Qualification1</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.jobPost.qual1Val}</td>
                                            </tr>

                                            {/* Row 3 */}
                                            <tr>
                                                {additionalDetails.jobPost.qual2Val && (
                                                    <>
                                                        <td style={{ width: '16.67%' }}><strong>Qualification2</strong></td>
                                                        <td style={{ width: '16.67%' }}>{additionalDetails.jobPost.qual2Val}</td>
                                                    </>
                                                )}
                                                {additionalDetails.jobPost.qual3Val && (
                                                    <>
                                                        <td style={{ width: '16.67%' }}><strong>Qualification3</strong></td>
                                                        <td style={{ width: '16.67%' }}>{additionalDetails.jobPost.qual3Val}</td>
                                                    </>
                                                )}
                                                <td style={{ width: '16.67%' }}><strong>Experience</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.jobPost.experience}</td>
                                            </tr>

                                            {/* Row 4 */}
                                            <tr>
                                                <td style={{ width: '16.67%' }}><strong>Gender Preferred</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.jobPost.gender}</td>
                                                <td style={{ width: '16.67%' }}><strong>Minimum Salary</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.jobPost.salaryFrom}</td>
                                                <td style={{ width: '16.67%' }}><strong>Maximum Salary</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.jobPost.salaryTo}</td>
                                            </tr>

                                            {/* Row 5 */}
                                            <tr>
                                                <td style={{ width: '16.67%' }}><strong>Status</strong></td>
                                                <td colSpan="5">
                                                    <select value={updatedStatus} onChange={(e) => setUpdatedStatus(e.target.value)}>
                                                        <option value="Open">Open</option>
                                                        <option value="Close">Close</option>
                                                    </select>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>


                                </div>
                            )}
                            {activeTab === 1 && additionalDetails?.uBasic && (
                                <div>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan="6" style={{ textAlign: 'center', fontSize: '18px', backgroundColor: '#dbd7d7' }}>
                                                    User Basic
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* Row 1 */}
                                            <tr>
                                                <td style={{ width: '16.67%' }}><strong>User Code</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.uBasic.uCode}</td>
                                                <td style={{ width: '16.67%' }}><strong>Name</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.uBasic.name}</td>
                                                <td style={{ width: '16.67%' }}><strong>Gender Preferred</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.uBasic.gender}</td>
                                            </tr>

                                            {/* Row 2 */}
                                            <tr>
                                                <td style={{ width: '16.67%' }}><strong>Date of Birth</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.uBasic.dob}</td>
                                                <td style={{ width: '16.67%' }}><strong>Address</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.uBasic.addressLine1}</td>
                                                <td style={{ width: '16.67%' }}><strong>District</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.uBasic.district}</td>


                                            </tr>

                                            {/* Row 3 */}
                                            <td style={{ width: '16.67%' }}><strong>State</strong></td>
                                            <td style={{ width: '16.67%' }}>{additionalDetails.uBasic.state}</td>
                                            <td style={{ width: '16.67%' }}><strong>Marital Status</strong></td>
                                            <td style={{ width: '16.67%' }}>{additionalDetails.uBasic.maritalStatus}</td>
                                            <td style={{ width: '16.67%' }}><strong>Religion</strong></td>
                                            <td style={{ width: '16.67%' }}>{additionalDetails.uBasic.religion}</td>

                                            {/* Row 4 */}
                                            <tr>
                                                <td style={{ width: '16.67%' }}><strong>Current Salary</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.uBasic.cursalary}</td>

                                            </tr>

                                            {/* Row 5 */}
                                            {additionalDetails?.uBasic?.introLink && (
                                                <tr>
                                                    <td style={{ width: '16.67%' }}><strong>Introduction Link</strong></td>
                                                    <td style={{ width: '16.67%' }} colSpan="3">
                                                        <a href={additionalDetails.uBasic.introLink}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {additionalDetails.uBasic.introLink}
                                                        </a>
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td style={{ width: '16.67%' }}><strong>Resume Download Link</strong></td>
                                                <td colSpan="5">
                                                    <a href={baseUrl + additionalDetails.uBasic.resumeLink}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {baseUrl + additionalDetails.uBasic.resumeLink}
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>


                                </div>
                            )}
                            {activeTab === 2 && additionalDetails?.uPrefBasic && (
                                <div>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan="6" style={{ textAlign: 'center', fontSize: '18px', backgroundColor: '#dbd7d7' }}>
                                                    User Preference Basic
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* Row 1 */}
                                            <tr>
                                                <td style={{ width: '16.67%' }}><strong>Minimum Salary</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.uPrefBasic.salaryFrom}</td>
                                                <td style={{ width: '16.67%' }}><strong>Maximum Salary</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.uPrefBasic.salaryTo}</td>
                                                <td style={{ width: '16.67%' }}><strong>Full Time</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.uPrefBasic.fullTime}</td>
                                            </tr>

                                            {/* Row 2 */}
                                            <tr>
                                                <td style={{ width: '16.67%' }}><strong>Mode</strong></td>
                                                <td style={{ width: '16.67%' }}>{additionalDetails.uPrefBasic.modeVal}</td>

                                            </tr>


                                        </tbody>
                                    </Table>


                                </div>
                            )}
                            {activeTab === 3 && additionalDetails?.uPrefBasic && (
                                <div>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan="6" style={{ textAlign: 'center', fontSize: '18px', backgroundColor: '#dbd7d7' }}>
                                                    User Preferences - Locations
                                                </th>
                                            </tr>
                                            <tr>
                                                <th style={{ width: '16.67%', fontWeight: 'bold' }}>State</th>
                                                <th style={{ width: '16.67%', fontWeight: 'bold' }}>District</th>
                                                <th style={{ width: '16.67%', fontWeight: 'bold' }}>City</th>
                                            </tr>
                                        </thead>


                                        <tbody>
                                            {additionalDetails?.uPrefLocations?.map((location) => (
                                                <tr key={location.id}>
                                                    <td>{location.stateVal}</td>
                                                    <td>{location.districtVal}</td>
                                                    <td>{location.city}</td>
                                                </tr>
                                            ))}
                                        </tbody>



                                    </Table>


                                </div>
                            )}
                            {activeTab === 4 && additionalDetails?.userPrefOthers && (
                                <div>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan="6" style={{ textAlign: 'center', fontSize: '18px', backgroundColor: '#dbd7d7' }}>
                                                    User Preferences - Facilities
                                                </th>
                                            </tr>

                                        </thead>


                                        <tbody>
                                            {additionalDetails?.userPrefOthers?.map((prefOther) => (
                                                <tr key={prefOther.id}>
                                                    <td>{prefOther.typeVal}</td>

                                                </tr>
                                            ))}
                                        </tbody>



                                    </Table>


                                </div>
                            )}
                            {activeTab === 5 && additionalDetails?.uLangs && (
                                <div>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan="6" style={{ textAlign: 'center', fontSize: '18px', backgroundColor: '#dbd7d7' }}>
                                                    Language Proficiency
                                                </th>
                                            </tr>
                                            <tr>
                                                <th style={{ width: '16.67%', fontWeight: 'bold' }}>Language</th>
                                                <th style={{ width: '16.67%', fontWeight: 'bold' }}>Read</th>
                                                <th style={{ width: '16.67%', fontWeight: 'bold' }}>Write</th>
                                                <th style={{ width: '16.67%', fontWeight: 'bold' }}>Speak</th>

                                            </tr>
                                        </thead>


                                        <tbody>
                                            {additionalDetails?.uLangs?.map((language) => (
                                                <tr key={language.id}>
                                                    <td>{language.langVal}</td>
                                                    <td>{language.read ? 'Yes' : 'No'}</td>
                                                    <td>{language.write ? 'Yes' : 'No'}</td>
                                                    <td>{language.overall ? 'Yes' : 'No'}</td>

                                                </tr>
                                            ))}

                                        </tbody>



                                    </Table>


                                </div>
                            )}
                            {activeTab === 6 && additionalDetails?.uEducationals && (
                                <div>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan="6" style={{ textAlign: 'center', fontSize: '18px', backgroundColor: '#dbd7d7' }}>
                                                    Educational Details
                                                </th>
                                            </tr>
                                            <tr>
                                                <th style={{ width: '16.67%', fontWeight: 'bold' }}>Qualifications</th>
                                                <th style={{ width: '16.67%', fontWeight: 'bold' }}>Aggreegate</th>
                                                <th style={{ width: '16.67%', fontWeight: 'bold' }}>Instiute</th>
                                                <th style={{ width: '16.67%', fontWeight: 'bold' }}>Year</th>

                                            </tr>
                                        </thead>


                                        <tbody>
                                            {additionalDetails?.uEducationals?.map((educational) => (
                                                <tr key={educational.id}>
                                                    <td>{educational.qualificationVal}</td>
                                                    <td>{educational.aggregate}</td>
                                                    <td>{educational.institute}</td>
                                                    <td>{educational.year}</td>


                                                </tr>
                                            ))}
                                        </tbody>



                                    </Table>


                                </div>
                            )}
                            {activeTab === 7 && additionalDetails?.uProffs && (
                                <div>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th colSpan="6" style={{ textAlign: 'center', fontSize: '18px', backgroundColor: '#dbd7d7' }}>
                                                    Professional Details
                                                </th>
                                            </tr>
                                            <tr>
                                                <th style={{ width: '16.67%', fontWeight: 'bold' }}>Institute</th>
                                                <th style={{ width: '16.67%', fontWeight: 'bold' }}>Role</th>
                                                <th style={{ width: '16.67%', fontWeight: 'bold' }}>Start Date</th>
                                                <th style={{ width: '16.67%', fontWeight: 'bold' }}>End Date</th>

                                            </tr>
                                        </thead>


                                        <tbody>
                                            {additionalDetails?.uProffs?.map((professional) => (
                                                <tr key={professional.id}>
                                                    <td>{professional.institute}</td>
                                                    <td>{professional.roleVal}</td>
                                                    <td>{professional.fromDate}</td>
                                                    <td>{professional.toDate}</td>
                                                </tr>
                                            ))}
                                        </tbody>



                                    </Table>


                                </div>
                            )}
                        </div>

                        <div className="job-button">
                            <Button variant="primary" onClick={generateAppointmentLetter}>
                                Generate Appointment Letter
                            </Button>
                            <Button variant="primary" onClick={handleSendIntrest}>
                                Send Interest
                            </Button>
                            <Button variant="danger" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </div>
                    </div>
                </div>

            )}
           <MySnackbar
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        snackbarMessage={snackbarMessage}
        snackbarBgColor={snackbarBgColor}
        isSuccess={isSuccess}
      />

        </>
    );


};

export default Suggestedcandidate;
