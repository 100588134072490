
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import React, { useState } from "react";

const Login = () => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <>
      <footer className="py-5">
        <Container>
          <Row className="align-items-center justify-content-xl-between" style={{ display: "flex", }}>
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted">
                Copyright© {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href={`${process.env.PUBLIC_URL}/TermsCondition.html`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Svasti Innovation Labs Pvt Ltd
                </a>

              </div>
              {showModal && <TermsCondition onClose={toggleModal} />}
            </Col>

          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
