import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { baseUrl } from '../examples/Constant';
import { useAuth } from "views/examples/AuthContext";
import { useApiUrl } from "views/examples/ApiUrlContext";
import { useNavigate } from 'react-router-dom';
import useUtil from "views/examples/Util"
import Topbar from "../examples/TopBar"

const MyAccount = () => {
  const { userData } = useAuth();
  const { userLoginCode } = useAuth();
  const { apiUrl } = useApiUrl();
  const { instbasic } = useAuth();
  const [selectedPlan, setSelectedPlan] = useState("basic");
  const [currentPlanDetails, setCurrentPlanDetails] = useState({
    currentPlan: "",
    planStartDate: "",
    planEndDate: "",
  });
  const [currentIBasicDetails, setCurrentIBasicDetails] = useState({
    logo: "",
    website: "",
  });
  const [currentInstiteDetails, setCurrentInstituteDetails] = useState({
    code: "",
    name: "",
  });
  const [availablePlans, setAvailablePlans] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !userData || 
      userData === undefined || 
      userData === '' || 
      (typeof userData === 'object' && Object.keys(userData).length === 0)
    ) {
      navigate('/auth/login'); 
    }
  }, [userData, navigate]);

  useEffect(() => {
    // Fetch current plan details, start date, and end date from the API
    const fetchPlanData = async () => {
      try {
        const bearerToken = userData.accessToken;
        const response = await fetch(
          `${apiUrl}/iplan/code/${userLoginCode.name}`,
          {
            headers: {
             'Authorization': `Bearer ${bearerToken}`,
              'Content-Type': 'application/json', // Adjust the content type if needed
            },
          })
        const data = await response.json();
        setCurrentPlanDetails(data);
        if (response && (response.status === 403 || response.status === 400)) {
          const headers = response.headers;
          if (headers && headers.Exception === 'SignatureException') {
            await useUtil.logout();
            // clear shared Pref & perform logout
          } else if (headers && headers.Exception === 'ExpiredJwtException') {
            bearerToken = await useUtil.refreshToken();
            // Use the newBearerToken as needed
          }
        }// Update state with fetched data
      } catch (error) {
     

      }
    };

    fetchPlanData(); // Call the fetchData function when the component mounts
  }, [userData]); // Dependency on userData ensures the effect re-runs when userData changes

  useEffect(() => {
    // Fetch current plan details, start date, and end date from the API
    const fetchInstituteData = async () => {
      try {
        const bearerToken = userData.accessToken;
        const response = await fetch(
          `${apiUrl}/institute/code/${userLoginCode.name}`,
          {
            headers: {
             'Authorization': `Bearer ${bearerToken}`,
              'Content-Type': 'application/json', // Adjust the content type if needed
            },
          })
        const data = await response.json();
        setCurrentInstituteDetails(data);
        if (response && (response.status === 403 || response.status === 400)) {
          const headers = response.headers;
          if (headers && headers.Exception === 'SignatureException') {
            await useUtil.logout();
            // clear shared Pref & perform logout
          } else if (headers && headers.Exception === 'ExpiredJwtException') {
            bearerToken = await useUtil.refreshToken();
            // Use the newBearerToken as needed
          }
        } // Update state with fetched data
      } catch (error) {
     

      }
    };

    fetchInstituteData(); // Call the fetchData function when the component mounts
  }, [userData]); // Dependency on userData ensures the effect re-runs when userData changes

  useEffect(() => {
    // Fetch current plan details, start date, and end date from the API
    const fetchIBasicDetails = async () => {
      try {
        const bearerToken = userData.accessToken;
        const response = await fetch(
          `${apiUrl}/iBasic/code/${userLoginCode.name}`,
          {
            headers: {
             'Authorization': `Bearer ${bearerToken}`,
              'Content-Type': 'application/json', // Adjust the content type if needed
            },
          })
        const data = await response.json();
        setCurrentIBasicDetails(data);
        if (response && (response.status === 403 || response.status === 400)) {
          const headers = response.headers;
          if (headers && headers.Exception === 'SignatureException') {
            await useUtil.logout();
            // clear shared Pref & perform logout
          } else if (headers && headers.Exception === 'ExpiredJwtException') {
            bearerToken = await useUtil.refreshToken();
            // Use the newBearerToken as needed
          }
        } // Update state with fetched data
      } catch (error) {
      

      }
    };

    fetchIBasicDetails(); // Call the fetchData function when the component mounts
  }, [userData]); // Dependency on userData ensures the effect re-runs when userData changes

  useEffect(() => {
    // Fetch available plans from the API
    const fetchAvailablePlans = async () => {
      try {
        const bearerToken = userData.accessToken;
        const response = await fetch(`${apiUrl}/plans/Type/i`, {
          headers: {
          'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json', // Adjust the content type if needed
          },
        })
        const data = await response.json();
        setAvailablePlans(data);
        if (response && (response.status === 403 || response.status === 400)) {
          const headers = response.headers;
          if (headers && headers.Exception === 'SignatureException') {
            await useUtil.logout();
            // clear shared Pref & perform logout
          } else if (headers && headers.Exception === 'ExpiredJwtException') {
            bearerToken = await useUtil.refreshToken();
            // Use the newBearerToken as needed
          }
        } // Update state with fetched plans
      } catch (error) {
       

      }
    };

    fetchAvailablePlans();
  }, []);

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh", }}
      >
        <Row className="justify-content-center">
          <Col md="12">
          <Topbar />
            <Card className="shadow-lg mb-4">
                  <CardBody className="text-center">
                    {/* Display the logo in circular shape */}
                    <div className="mb-3" style={{
                      width: "120px", height: "120px", borderRadius: "50%", overflow: "hidden",marginInline : "auto",
                      display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white"
                    }} >
                      <img src={baseUrl + currentIBasicDetails.logo} alt="Institute Logo" style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '50%' }} />
                    </div>

                    <p>{currentInstiteDetails.code}</p>
                    <p>{currentInstiteDetails.name}</p>
                    <p>{currentIBasicDetails.website}</p>
                  </CardBody>
                </Card>

            <div className="mt-4">
              <h2 style={{ fontWeight: 'bold', color: 'black' }} className="mt-3">Subscription Plans</h2>
              <Row>
                {availablePlans.map((plan) => (
                  <Col key={plan.id} md="3">
                    <Card
                      style={{
                        backgroundColor: currentPlanDetails.plan === plan.code ? "lightblue" : "gold",
                        borderRadius: "10px",
                        border: "1px solid #ced4da",
                      }}
                      className={selectedPlan === plan.id ? "selected" : ""}
                    >
                      <CardBody>
                        <h5 style={{ fontWeight: "bold" }}>{plan.name}</h5>
                        <p>{plan.details}</p>
                        <p>Upgrade Amount: ₹{plan.price}</p>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MyAccount;
