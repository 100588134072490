import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useApiUrl } from "views/examples/ApiUrlContext";
import { useAuth } from "views/examples/AuthContext";
import axios from 'axios';
import "./suggestedjobs.css";
import useUtil from "views/examples/Util";
import { Tabs, Tab } from '@mui/material';
import { styled } from '@mui/system';
import MySnackbar from 'views/examples/Snackbar'
import { useNavigate } from 'react-router-dom';
import Topbar from "../examples/TopBar"


const MyJobs = () => {
  const { apiUrl } = useApiUrl();
  const { userData } = useAuth();
  const { userLoginCode } = useAuth();
  const { instbasic } = useAuth();
  const [instPlan, setInstPlan] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [candidatedetails, setCandidateDetails] = useState(null);
  const [additionalDetails, setAdditionalDetails] = useState(null);
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarBgColor, setSnackbarBgColor] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !userData ||
      userData === undefined ||
      userData === '' ||
      (typeof userData === 'object' && Object.keys(userData).length === 0)
    ) {
      navigate('/auth/login');
    }
  }, [userData, navigate]);

  const fetchInstiutePlans = async () => {
    try {
      const bearerToken = userData.accessToken;
      const response = await axios.get(
        `${apiUrl}/iplan/code/${userLoginCode.name}`,
        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setInstPlan(response.data.plan);
    } catch (error) {
      console.error("Error fetching institute plans:", error);

    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const CustomTabs = styled(Tabs)({
    backgroundColor: 'teal',
    borderRadius: '8px',
    padding: '5px',
    border: '2px solid teal',
  });

  const CustomTab = styled(Tab)({
    color: '#fff',
    '&.Mui-selected': {
      backgroundColor: 'black', // Set active tab background to black
      color: '#fff', // Keep the text color white for contrast
      borderRadius: '8px',
    },
    '&:not(.Mui-selected)': {
      marginRight: '60px',
    },
    textTransform: 'none',
  });
  const fetchData = async () => {
    try {
      const bearerToken = userData.accessToken;
      const response = await fetch(
        `${apiUrl}/jobapplications/i/${userLoginCode.name}`,
        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json', // Adjust the content type if needed
          },
        })
      const data = await response.json();

      setJobs(data);
      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.Exception === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.Exception === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }
    } catch (error) {

    }
  };

  const fetchAdditionalDetails = async (selectedJob) => {
    try {
      const bearerToken = userData.accessToken;
      const response = await fetch(
        `${apiUrl}/jobpost/code/${selectedJob.jCode}`,
        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json', // Adjust the content type if needed
          },
        })
      const data = await response.json();
      setAdditionalDetails(data);
      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.Exception === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.Exception === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }
    } catch (error) {

    }
  };

  const fetchCandidateDetails = async (selectedJob) => {
    try {
      const bearerToken = userData.accessToken;
      const response = await fetch(
        `${apiUrl}/ubasic/code/${selectedJob.uCode}`,

        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json', // Adjust the content type if needed
          },
        });
      const data = await response.json();
      setCandidateDetails(data);
      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.Exception === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.Exception === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }
    } catch (error) {

    }
  };

  useEffect(() => {
    fetchData();
    fetchInstiutePlans();
  }, []);

  const handleViewMore = (job) => {
    setSelectedJob(job);
    fetchAdditionalDetails(job);
    fetchCandidateDetails(job);
    setUpdatedStatus(job.status);
  };

  const handleCloseModal = () => {
    setSelectedJob(null);
    setUpdatedStatus("");
    setActiveTab(0);
  };

 
  const handleUpdateStatus = async () => {
    const currentDate = new Date();
    const endDate = new Date();
    endDate.setMonth(currentDate.getMonth() + 6);

    try {
      const bearerToken = userData.accessToken;

      // First API call
      const response = await axios.put(
        `${apiUrl}/jobapplications/updatestatus/${selectedJob.jCode}`,
        { status: updatedStatus, uCode: selectedJob.uCode },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        // Second API call
        const alertResponse = await axios.post(
          `${apiUrl}/ualert`,
          {
            jobCode: selectedJob.jCode,
            ucode: selectedJob.uCode,
            type: 'For me',
            data: updatedStatus,
            startDt: currentDate.toISOString().split('T')[0], // Format date as YYYY-MM-DD
            endDt: endDate.toISOString().split('T')[0],       // Format date as YYYY-MM-DD
          },
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
              'Content-Type': 'application/json',
            },
          }
        );

        // Handle the response from the second API call
        if (alertResponse.status === 200) {
          setSnackbarMessage("Status and notification send to applied users successfully");
        }
        setSnackbarBgColor('green');
        setIsSuccess(true);
        setSnackbarOpen(true);
        fetchData();
        handleCloseModal();
      } else if (response.status === 403 || response.status === 400) {
        const headers = response.headers;
        if (headers && headers.Exception === 'SignatureException') {
          await useUtil.logout();
        } else if (headers && headers.Exception === 'ExpiredJwtException') {
          // Handle token expiration, e.g., refresh token
        }
      } else {
        setSnackbarMessage("Error updating status");
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Error updating status. Please check.");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <>

      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh", }}
      >
        <Topbar />
        <div>
          <div
            style={{ padding: "10px" }}
          >
            <div style={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)", padding: "10px", }}>

              <div style={{ display: "flex" }}>

                <div style={{ flex: 1, }}>
                  <div
                    style={{

                      padding: 10,
                    }}
                  >
                    <div>
                      {jobs.map((job) => (
                        <Table key={job.id} striped bordered hover>
                          <tbody>
                            <tr>
                              {job.jCode && (
                                <>
                                  <td style={{ width: "15%" }}>
                                    <strong>Job Code</strong>
                                  </td>
                                  <td style={{ width: "15%" }}>{job.jCode}</td>
                                </>
                              )}
                              {job.date && (
                                <>
                                  <td style={{ width: "20%" }}>
                                    <strong>Applied Date</strong>
                                  </td>
                                  <td style={{ width: "35%" }}>{job.date}</td>
                                </>
                              )}
                              <td style={{ width: "15%" }}>
                                <strong>Action</strong>
                              </td>
                            </tr>
                            <tr>
                              {job.status && (
                                <>
                                  <td style={{ width: "15%" }}>
                                    <strong>Status</strong>
                                  </td>
                                  <td style={{ width: "15%" }}>{job.status}</td>
                                </>
                              )}

                              <>
                                <td style={{ width: "20%" }}>
                                  <strong>Institute Remarks</strong>
                                </td>
                                <td style={{ width: "35%" }}>{job.instituteRemark}</td>
                              </>

                              <td style={{ width: "15%" }}>
                                <Button variant="primary" onClick={() => handleViewMore(job)}>
                                  View More
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!!selectedJob && (
        <div className="myJobs-modal-overlay">
          <div className="myJobs-modal-content">
            <div className="myJobs-modal-header">
              <h2 className="modal-title">Job Details</h2>
              <button className="cancel-button" onClick={handleCloseModal}>
                &#x2715;
              </button>
            </div>
            <div className="myJobs-modal-body">
              <CustomTabs
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="job suggestion tabs"
              >
                <CustomTab label="Application Details" />
                <CustomTab label="Job Details" />
                <CustomTab label="Candidate Details" />

              </CustomTabs>
              {activeTab === 0 && selectedJob && (
                <div>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th
                          colSpan="2"
                          style={{
                            textAlign: "center",
                            fontSize: "18px",
                            backgroundColor: "#dbd7d7",
                          }}
                        >
                          Application Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedJob.date && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <strong>Applied Date</strong>
                          </td>
                          <td style={{ width: "75%" }}>{selectedJob.date}</td>
                        </tr>
                      )}
                      {selectedJob.status && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <strong>Status</strong>
                          </td>
                          <td style={{ width: "75%" }}>{selectedJob.status}</td>
                        </tr>
                      )}
                      {selectedJob.instituteRemark && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <strong>Institute Remark</strong>
                          </td>
                          <td style={{ width: "75%" }}>{selectedJob.instituteRemark}</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              )}

              {activeTab === 1 && additionalDetails && (
                <div>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th
                          colSpan="2"
                          style={{
                            textAlign: "center",
                            fontSize: "18px",
                            backgroundColor: "#dbd7d7",
                          }}
                        >
                          Job Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {additionalDetails.date && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <strong>Posted Date</strong>
                          </td>
                          <td style={{ width: "75%" }}>{additionalDetails.date}</td>
                        </tr>
                      )}
                      {additionalDetails.typeVal && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <strong>Type</strong>
                          </td>
                          <td style={{ width: "75%" }}>{additionalDetails.typeVal}</td>
                        </tr>
                      )}
                      {additionalDetails.roleVal && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <strong>Role</strong>
                          </td>
                          <td style={{ width: "75%" }}>{additionalDetails.roleVal}</td>
                        </tr>
                      )}
                      {additionalDetails.subject1Val && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <strong>Subject1</strong>
                          </td>
                          <td style={{ width: "75%" }}>{additionalDetails.subject1Val}</td>
                        </tr>
                      )}
                      {additionalDetails.subject2Val && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <strong>Subject2</strong>
                          </td>
                          <td style={{ width: "75%" }}>{additionalDetails.subject2Val}</td>
                        </tr>
                      )}
                      {additionalDetails.qual1Val && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <strong>Qualification1</strong>
                          </td>
                          <td style={{ width: "75%" }}>{additionalDetails.qual1Val}</td>
                        </tr>
                      )}
                      {additionalDetails.qual2Val && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <strong>Qualification2</strong>
                          </td>
                          <td style={{ width: "75%" }}>{additionalDetails.qual2Val}</td>
                        </tr>
                      )}
                      {additionalDetails.qual3Val && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <strong>Qualification3</strong>
                          </td>
                          <td style={{ width: "75%" }}>{additionalDetails.qual3Val}</td>
                        </tr>
                      )}
                      {additionalDetails.experience && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <strong>Experience</strong>
                          </td>
                          <td style={{ width: "75%" }}>{additionalDetails.experience}</td>
                        </tr>
                      )}
                      {additionalDetails.gender && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <strong>Gender Preferred</strong>
                          </td>
                          <td style={{ width: "75%" }}>{additionalDetails.gender}</td>
                        </tr>
                      )}
                      {additionalDetails.salaryFrom && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <strong>Minimum Salary</strong>
                          </td>
                          <td style={{ width: "75%" }}>{additionalDetails.salaryFrom}</td>
                        </tr>
                      )}
                      {additionalDetails.salaryTo && (
                        <tr>
                          <td style={{ width: "25%" }}>
                            <strong>Maximum Salary</strong>
                          </td>
                          <td style={{ width: "75%" }}>{additionalDetails.salaryTo}</td>
                        </tr>
                      )}
                      <tr>
                        <td style={{ width: "25%" }}>
                          <strong>Status</strong>
                        </td>
                        <td style={{ width: "75%" }}>
                          <select
                            value={updatedStatus}
                            onChange={(e) => setUpdatedStatus(e.target.value)}
                          >
                            <option value="Open">Open</option>
                            <option value="Rejected">Rejected</option>
                            <option value="Selected">Selected</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}

              {activeTab === 2 && candidatedetails && (
                <div>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th
                          colSpan="2"
                          style={{
                            textAlign: "center",
                            fontSize: "18px",
                            backgroundColor: "#dbd7d7",
                          }}
                        >
                          Candidate Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      {candidatedetails.name && (
                        <tr>
                          <td>
                            <strong>Name</strong>
                          </td>
                          <td>{candidatedetails.name}</td>
                        </tr>
                      )}
                      {candidatedetails.email && (
                        <tr>
                          <td>
                            <strong>Email</strong>
                          </td>
                          <td>{instPlan === '1' ? '******' : candidatedetails.email}</td>
                        </tr>
                      )}
                      {candidatedetails.phone && (
                        <tr>
                          <td>
                            <strong>Phone</strong>
                          </td>
                          <td>{instPlan === '1' ? '******' : candidatedetails.phone}</td>
                        </tr>
                      )}
                      {candidatedetails.dob && (
                        <tr>
                          <td>
                            <strong>Date of Birth</strong>
                          </td>
                          <td>{candidatedetails.dob}</td>
                        </tr>
                      )}
                      {candidatedetails.addressLine1 && (
                        <tr>
                          <td>
                            <strong>Address</strong>
                          </td>
                          <td>{candidatedetails.addressLine1}</td>
                        </tr>
                      )}
                      {candidatedetails.city && (
                        <tr>
                          <td>
                            <strong>City</strong>
                          </td>
                          <td>{candidatedetails.city}</td>
                        </tr>
                      )}
                      {candidatedetails.gender && (
                        <tr>
                          <td>
                            <strong>Gender Preferred</strong>
                          </td>
                          <td>{candidatedetails.gender}</td>
                        </tr>
                      )}
                      {candidatedetails.selfDescr && (
                        <tr>
                          <td>
                            <strong>Self Description</strong>
                          </td>
                          <td>{candidatedetails.selfDescr}</td>
                        </tr>
                      )}
                      {candidatedetails.introLink && (
                        <tr>
                          <td>
                            <strong>Introduction Video Link</strong>
                          </td>
                          <td>
                            <a
                              href={candidatedetails.introLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {candidatedetails.introLink}
                            </a>
                          </td>
                        </tr>
                      )}
                      {candidatedetails.demoLink && (
                        <tr>
                          <td>
                            <strong>Demo Video Link</strong>
                          </td>
                          <td>
                            <a
                              href={candidatedetails.demoLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {candidatedetails.demoLink}
                            </a>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              )}


            </div>
            <div className="myJobs-button">
              <Button variant="success" onClick={handleUpdateStatus}>
                Update
              </Button>
              <Button variant="danger" onClick={handleCloseModal}>
                Close
              </Button>
            </div>
          </div>
        </div>

      )}

<MySnackbar
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        snackbarMessage={snackbarMessage}
        snackbarBgColor={snackbarBgColor}
        isSuccess={isSuccess}
      />
    </>
  );
};

export default MyJobs;
