import Index from "views/Index.js";
import Login from "views/examples/Login.js";
import Recruitment from "views/examples/Recruitment";
import SignUp from "views/examples/SignUp";
import MyAccount from "views/examples/MyAccount";
import Notification from "views/examples/Notifications";
import Letters from "views/examples/Letters";
import Settings from "views/examples/Settings";
import Employee from "views/examples/Employee";
import Profile from "views/examples/Profile";
import HelpPage from "views/examples/Help";
import MyJobs from "views/examples/MyJobs"
import Suggestedcandidate from "views/examples/SuggestedCandidate"
import Feedbacks from "views/examples/Feedback"
import AboutUs from "views/examples/AboutUs"


var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 ",
    component: <Index />,
    layout: "/admin",
  },

  {
    path: "/SignUp",
    name: "SignUp",
    icon: "ni ni-tv-2 ",
    component: <SignUp />,
    layout: "/auth",
  },

  {
    path: "/Profile",
    name: "Profile",
    icon: "fas fa-user-circle",
    component: <Profile />,
    layout: "/admin",
  },

  {
    path: "/Recruitment",
    name: "Recruitment",
    icon: "fas fa-users",
    component: <Recruitment />,
    layout: "/admin",
  },
  {
    path: "/myjobs",
    name: "Received Applications",
    icon: "ni ni-single-copy-04",
    component: <MyJobs />,
    layout: "/admin",
  },
  {
    path: "/Suggestedcandidate",
    name: "Suggested Candidate",
    icon: "ni ni-single-02",
    component: <Suggestedcandidate />,
    layout: "/admin",
  },
  {
    path: "/Employee",
    name: "Manage Employees ",
    icon: "fas fa-users",
    component: <Employee />,
    layout: "/admin",
  },

  {
    path: "/MyAccount",
    name: "My Account",
    icon: "fas fa-user-circle",
    component: <MyAccount />,
    layout: "/admin",
  },

  {
    path: "/Notification",
    name: "Notifications",
    icon: "ni ni-bell-55",
    component: <Notification />,
    layout: "/admin",
  },

  {
    path: "/Letters",
    name: "Letters",
    icon: "ni ni-email-83",
    component: <Letters />,
    layout: "/admin",
  },

  {
    path: "/Settings",
    name: "Settings",
    icon: "ni ni-settings ",
    component: <Settings />,
    layout: "/admin",
  },
 

  {
    path: "/Help",
    name: "Help",
    icon: "fas fa-question-circle", 
    component: <HelpPage />,
    layout: "/admin",
  },

  {
    path: "/About",
    name: "About",
    icon: "fas fa-question-circle", 
    component: <AboutUs />,
    layout: "/admin",
  },


  {
    path: "/Feedbacks",
    name: "Feedbacks",
    icon: "fas fa-comment", // Choose an appropriate icon for Help
    component: <Feedbacks />,
    layout: "/admin",
  },

  {
    path: "/login",
    name: "Logout",
    icon: "ni ni-button-power ",
    component: <Login />,
    layout: "/auth",
  },
 
 
];
export default routes;
