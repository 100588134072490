import { useState, useEffect } from "react";
import classnames from "classnames";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import axios from "axios";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import { useAuth } from "views/examples/AuthContext";
import { useNavigate } from 'react-router-dom';
import { useApiUrl } from "views/examples/ApiUrlContext";
import { Spinner } from 'reactstrap';
import Header from "components/Headers/Header.js";
import Topbar from "views/examples/TopBar"

const Index = (props) => {
  const { userData } = useAuth();
  const { apiUrl } = useApiUrl();
  const { userLoginCode } = useAuth();
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState(null);
  const [chartExample2Data, setChartExample2Data] = useState(null);
  const navigate = useNavigate();

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {
    if (
      !userData || 
      userData === undefined || 
      userData === '' || 
      (typeof userData === 'object' && Object.keys(userData).length === 0)
    ) {
      navigate('/auth/login'); 
    }
  }, [userData, navigate]);

  useEffect(() => {
    const monthNames = {
      1: 'Jan',
      2: 'Feb',
      3: 'Mar',
      4: 'Apr',
      5: 'May',
      6: 'Jun',
      7: 'Jul',
      8: 'Aug',
      9: 'Sep',
      10: 'Oct',
      11: 'Nov',
      12: 'Dec',
      // Add more months as needed
    };

    const bearerToken = userData.accessToken;

    // Fetch data for chartExample1
    axios.get(`${apiUrl}/iBasic/chartdatajp/${userLoginCode.name}`, {
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
      }
    })
      .then(response => {
        const data = response.data.map(([month, value]) => [monthNames[month], value]);
        const labels = data.map(item => item[0]);
        const values = data.map(item => item[1]);
        setChartExample1Data({
          labels: labels,
          datasets: [
            {
              label: "Job Posts",
              data: values,
            },
          ],
        });
      })
      .catch(error => {
        console.error('Error fetching chartExample1 data:', error);
      });

    // Fetch data for chartExample2
    axios.get(`${apiUrl}/iBasic/chartdataja/100002`, {
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
      }
    })
      .then(response => {
        const data = response.data.map(([month, value]) => [monthNames[month], value]);
        const labels = data.map(item => item[0]);
        const values = data.map(item => item[1]);
        setChartExample2Data({
          labels: labels,
          datasets: [
            {
              label: "Job Applications",
              data: values,
              maxBarThickness: 10,
            },
          ],
        });
      })
      .catch(error => {

      });
  }, []);


  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };

  return (
    <>
    <Topbar />
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">Job Postings</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  {chartExample1Data && (
                    <Line
                      data={chartExample1Data}
                      options={chartExample1.options}
                      getDatasetAtEvent={(e) => console.log(e)}
                    />
                  )}
                </div>

              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Bar view
                    </h6>
                    <h2 style={{ color: "black" }}>Job Applications</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  {chartExample2Data && (
                    <Bar
                      data={chartExample2Data}
                      options={chartExample2.options}

                    />
                  )}
                </div>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
