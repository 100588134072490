import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Logo from "../../assets/img/brand/shishakbandulogo.PNG";
import { env, localUrl } from '../examples/Constant';
import './BlogPost.css';

const BlogPost = () => {
    const { id } = useParams();
    const [blog, setBlog] = useState(null);



    useEffect(() => {
        // Fetch blog details based on ID from useParams().id
        const fetchBlog = async () => {
            try {
                const response = await fetch(`${localUrl}/blogs/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch blog data');
                }
                const data = await response.json();
                setBlog(data);
            } catch (error) {
                
            }
        };

        fetchBlog();
    }, [id]);

    return (
        <div>
            <div className="topbar">
                <img src={Logo} alt="Logo" className="logo small-logo" />
                <h2 style={{ textAlign: 'center',fontSize: '25px', fontWeight: 'bold' }}>{env === 'P' ? 'Welcome to Shikshak Bandhu' : 'Welcome to Shikshak Bandhu......'}</h2>
                <h2 style={{ textAlign: 'center' }}>
     {'       '}
    </h2>
            </div>

            {/* Blog post content */}
            {blog && (
                <div className="blog-content">
                    <img src={blog.imageUrl} alt="Blog" className="center-image" />
                    <div className="summary">
                        <h2 className="center-text">Summary</h2>
                        {blog.description}
                    </div>
                    <div className="blog-content">
                        <div className="summary">
                            <h2 className="center-text">Details</h2>
                           {/* // {blog.detaildescptrion ? blog.detaildescptrion : 'Loading...'} */}
                         Hiii\nHow are you?
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BlogPost;
