import React, { useState, useEffect } from 'react';
import "./landing.css";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
  faGlobe,
  faUserCircle,
  faBriefcase,
  faFileAlt,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import { } from '@fortawesome/free-solid-svg-icons';
import { FaInstagram, FaTwitter, FaYoutube, FaFacebook } from 'react-icons/fa';

import InstPlans from "../../assets/img/brand/InsPlans.png";
import TeachersPlans from "../../assets/img/brand/StaffPlans.png";
import Logo from "../../assets/img/brand/shishakbandulogo.PNG";
import Homelogo from "../../assets/img/brand/Shbandu.PNG";
import { env, localUrl } from '../examples/Constant';


const HomeComponent = () => {
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);
  const [backgroundImageUrls, setBackgroundImageUrls] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [featuredJobs, setFeaturedJobs] = useState([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBackgroundIndex((prevIndex) => (prevIndex + 1) % backgroundImageUrls.length);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [backgroundImageUrls]);

  useEffect(() => {
    fetchBackgroundImages();
    fetchBlogs();
    fetchFeaturedJobs();
  }, []);
  const fetchBlogs = async () => {
    try {
      const response = await fetch(`${localUrl}/blogs/allblogs`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      setBlogs(data);
    } catch (error) {

    }
  };


  const fetchFeaturedJobs = async () => {
    try {
      const response = await fetch(`${localUrl}/jobpost/featurejobs`);
      if (!response.ok) {
        throw new Error('Failed to fetch featured jobs data');
      }
      const data = await response.json();
      setFeaturedJobs(data);
    } catch (error) {

    }
  };


  const fetchBackgroundImages = async () => {
    try {
      const response = await fetch(`${localUrl}/advertize/i`);
      if (!response.ok) {
        throw new Error('Failed to fetch background images');
      }
      const data = await response.json();
      setBackgroundImageUrls(data);
    } catch (error) {

    }
  };


  const handleDownloadAppClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.facebook.katana&hl=en_IN&gl=US",
      "_blank"
    );
  };



  return (
    <>
      <div className="home-component" style={{ backgroundImage: `url(${backgroundImageUrls[currentBackgroundIndex] || Homelogo})` }}>
        <div className="slider-dots-container">
          {backgroundImageUrls.map((url, index) => (
            <div
              key={index}
              className={`slider-dot ${index === currentBackgroundIndex ? 'active' : ''}`}
              onClick={() => setCurrentBackgroundIndex(index)}
            ></div>
          ))}
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: '10px', }}>
        <h2 style={{ color: 'black', fontSize: '25px', marginTop: '40px' }}>Steps for Institutes to Post Jobs</h2>
        <div className="blog-card">
          <div className="steps-wrapper">
            <div className="step">
              <FontAwesomeIcon icon={faUserCircle} size="3x" style={{ color: '#1B2547' }} />
              <p style={{ fontWeight: 'bold' }}>1.Create a profile on our platform.</p>
            </div>
            <div className="step">
              <FontAwesomeIcon icon={faBriefcase} size="3x" style={{ color: '#1B2547' }} />
              <p style={{ fontWeight: 'bold' }}>2.Post your job listings with detailed descriptions.</p>
            </div>
            <div className="step">
              <FontAwesomeIcon icon={faFileAlt} size="3x" style={{ color: '#1B2547' }} />
              <p style={{ fontWeight: 'bold' }}>3.Review applications submitted by candidates.</p>
            </div>
            <div className="step">
              <FontAwesomeIcon icon={faUsers} size="3x" style={{ color: '#1B2547' }} />
              <p style={{ fontWeight: 'bold' }}>4.Hire suitable candidates for your institution.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-section">
        <h1 style={{ color: 'black', textAlign: 'center', fontSize: '25px', marginTop: '20px' }}>Latest Blogs</h1>
        <div className="blog-grid">
          {blogs.map(blog => (
            <div key={blog.id} className="blog-card" style={{ cursor: 'pointer' }}
              onClick={() => window.open(`/blog/${blog.id}`)}>
              <Card.Title className="custom-title">{blog.title}</Card.Title>
              <Card.Img variant="top" src={blog.imageUrl} alt="Blog Image" />
              <Card.Body>
                <Card.Text style={{ fontFamily: "Arial" }}>
                  {blog.description}
                </Card.Text>
              </Card.Body>

            </div>
          ))}
        </div>
      </div>
      <div className="featured-jobs-section">
        <h1 style={{ color: 'black', textAlign: 'center', fontSize: '25px', marginTop: '20px' }}>Featured Jobs</h1>
        <div className="featured-jobs-row">
          {featuredJobs && featuredJobs.length > 0 && (
            featuredJobs.reduce((rows, job, index) => {
              if (index % 4 === 0) rows.push([]);
              rows[rows.length - 1].push(
                <Col key={job.id} md={3}>
                  <div className="job-card">
                    <Card.Body className="fixed-size-card">
                      <Card.Header style={{ backgroundColor: '#1B2547', color: 'white', fontSize: '23px' }}>{job.roleVal}</Card.Header>
                      <Card.Text className="custom-font-size">
                        <i className="fas fa-money-bill-alt"></i> ₹{job.salaryFrom} To ₹{job.salaryTo}
                      </Card.Text>
                      <Card.Text className="custom-font-size">
                        <i className="far fa-calendar-alt"></i> {job.schdate}
                      </Card.Text>
                      <Card.Text className="custom-font-size">
                        <i className="fas fa-user-clock"></i> {job.fulltime === 'PT' ? 'Part Time' : 'Full Time'}
                      </Card.Text>
                      <div className="mt-auto d-flex justify-content-end">
                        <Button variant="warning" onClick={handleDownloadAppClick}>
                          Apply <i className="fas fa-chevron-right ml-2"></i>
                        </Button>
                      </div>

                    </Card.Body>
                  </div>
                </Col>
              );
              return rows;
            }, []).map((row, index) => (
              <Row key={index} className="justify-content-md-center">
                {row}
              </Row>
            ))
          )}
        </div>
      </div>
    </>

  );
}

const AboutUsCard = () => {
  return (
    <div className="about-card">
      <Row>
        <Col md="5">
        <div style={{ paddingLeft: "20px", paddingRight: "20px", marginTop: "20px" }}>
                <div className="video-responsive" style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden", maxWidth: "100%", background: "#000", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", border: "3px solid #ddd", borderRadius: "8px" }}>
                  <iframe 
                    width="100%" 
                    height="100%" 
                    src="https://www.youtube.com/embed/tgbNymZ7vqY?controls=0" 
                    title="Shikshak Bandhu Introduction" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen 
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", borderRadius: "8px" }}>
                  </iframe>
                </div>
              </div>
        </Col>

        <Col md="7">
          <Card className="shadow-lg mb-4" style={{ display: 'flex', flexDirection: 'column' }}>
            <CardBody style={{ flex: '1' }}>
              <h3 className="section-heading">India's First Network of Private School Teachers</h3>
              <p style={{ fontFamily: "Arial", textAlign: "left", fontSize: "17px", paddingLeft: "20px" }}>
                Welcome to Shikshak Bandhu, India's largest network that connects Teachers and Schools with more than 1 Lakh eligible teachers as members and 8000 schools spread in more than 20 states and territories nationwide.
              </p>

              <h4 className="section-heading">Vision</h4>
              <p style={{ fontFamily: "Arial", textAlign: "left", fontSize: "17px", paddingLeft: "20px" }}>
                To create opportunities for every student through his/her school to find suitable teachers. A school with sufficient number of Creative, Productive and Active teachers can be a farm field of emerging hopeful citizens.
              </p>

              <h4 className="section-heading">Mission</h4>
              <p style={{ fontFamily: "Arial", textAlign: "left", fontSize: "17px", paddingLeft: "20px" }}>
                The mission of Shikshak Bandhu is simple as well as noble: Every private school teacher in India should find it easy to find his destination school. Similarly, no school should compromise with the situation of not getting a suitable teacher. Also, helping the stakeholders should be at affordable charges unlike any other traditional ways of providing the manpower or job opportunities.
              </p>

              <h4 className="section-heading">Who are we?</h4>
              <p style={{ fontFamily: "Arial", textAlign: "left", fontSize: "17px", paddingLeft: "20px" }}>
                The idea of Shikshak Bandhu began in the year 2020 with a simple online form during the Covid Pandemic as it was difficult to find suitable faculty required for our Client Schools. Today, Shikshak Bandhu is equipped with a dedicated Website (for Institutions) and Mobile App (for teachers) to keep them on set any time to get connected in a completely self-directed and hassle-free automated software with a big database in the background. It is run by a team of Teachers having decades of academic and administrative experience. The platform is very much tailor-made to provide complete human resource accumulation-related issues.
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};


// ContactUsCard component for displaying the "Contact Us" content in a card
const ContactUsCard = () => {
  const iconSize = "1.2rem";
  const redIconStyle = { color: "red" };
  return (
    <div className="about-card">
      <h3 className="section-heading">Contact Information</h3>
      <Row className="mb-1">
        <Col md={12}>
          <Card rounded>
            <Card.Body>
              <Row>
                <Col md={1} className="text-right">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    size="2x"
                    style={redIconStyle}
                  />
                </Col>
                <Col md={11}>
                  <p>
                    <strong>
                      Address: Shikshak Bandhu, KAPPEC,
                      Werehouse No2, Amargol Hubli, Karnataka 580025{" "}
                    </strong>
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col md={12}>
          <Card rounded>
            <Card.Body>
              <Row>
                <Col md={1} className="text-right">
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="2x"
                    style={redIconStyle}
                  />
                </Col>
                <Col md={11}>
                  <p>
                    <strong>Phone: 0836-2955727, +91-889988988 </strong>
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col md={12}>
          <Card rounded>
            <Card.Body>
              <Row>
                <Col md={1} className="text-right">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="2x"
                    style={redIconStyle}
                  />
                </Col>
                <Col md={11}>
                  <p>
                    <strong>Email:</strong>{" "}
                    <strong>
                      <a href="mailto:support@shikshakbandhu.com">
                        support@shikshakbandhu.com
                      </a>
                    </strong>
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col md={12}>
          <Card rounded>
            <Card.Body>
              <Row>
                <Col md={1}>
                  <FontAwesomeIcon
                    icon={faGlobe}
                    size="2x"
                    style={redIconStyle}
                  />
                </Col>
                <Col md={11}>
                  <p>
                    <strong>Website:</strong>{" "}
                    <strong>
                      <a
                        href="http://www.shikshakbandhu.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        http://www.shikshakbandhu.com/
                      </a>
                    </strong>
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col md={12}>
          <Card rounded>
            <Card.Body>
              <Row>
                <Col md={11}>
                  <strong>Follow us on Social Media:</strong>{" "}
                  <a
                    href="http://instagram.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram size={iconSize} style={redIconStyle} />
                  </a>{" "}
                  <a
                    href="http://facebook.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook size={iconSize} style={{ color: "blue" }} />
                  </a>{" "}
                  <a
                    href="http://youtube.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube size={iconSize} style={redIconStyle} />
                  </a>{" "}
                  <a
                    href="http://www.shikshakbandhu.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter size={iconSize} style={{ color: "blue" }} />
                  </a>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}


const Planscard = () => {
  return (
    <div className="about-card">
      <h3 className="section-heading">
        Plans for Institutions
      </h3>
      <img src={InstPlans} alt="Plans for Institutions" />
      <h3 className="section-heading" style={{ marginTop: '10px' }}>
        Plans for Teachers & Staff
      </h3>
      <img src={TeachersPlans} alt="Plans for Teachers & Staff" />
    </div>
  );
}

const LandingPage = () => {
  const [visibleDiv, setVisibleDiv] = useState("div1");
  const [showModal, setShowModal] = useState(false);


  const toggleModal = () => {
    setShowModal(!showModal);
  };


  const handleHomeClick = () => {
    setVisibleDiv("div1");
  };

  const handleAboutClick = () => {
    setVisibleDiv("div2");
  };

  const handleplanclick = () => {
    setVisibleDiv("div3");
  };

  const handleContactClick = () => {
    setVisibleDiv("div4");
  };
  const handleDownloadAppClick = () => {
    // Open the app link in a new window
    window.open(
      "https://play.google.com/store/apps/details?id=com.facebook.katana&hl=en_IN&gl=US",
      "_blank"
    );
  };


  return (
    <div>
      <div className="appbar">
        <img src={Logo} alt="Logo" className="logo" />
        <h2>{env === 'P' ? 'Welcome to Shikshak Bandhu' : 'Welcome to Shikshak Bandhu......'}</h2>
        <div className="nav-links">
          <button onClick={handleHomeClick}>Home</button>
          <button onClick={handleAboutClick}>About Us</button>
          <button onClick={handleplanclick}>Plans</button>
          <button onClick={handleContactClick}>Support</button>
          <button onClick={handleDownloadAppClick}>Download App for Teachers</button>
          <Link to="/login">Institute Login</Link>
        </div>
      </div>

      {visibleDiv === "div1" && (
        <HomeComponent />
      )}
      {visibleDiv === "div2" && (
        <div className="content-div">
          <div className="centered-card">
            <AboutUsCard />
          </div>
        </div>
      )}
      {visibleDiv === "div3" && (
        <div className="centered-card">
          <Planscard />
        </div>
      )}

      {visibleDiv === "div4" && (
        <div className="content-div">
          <div className="centered-card">
            <ContactUsCard />
          </div>
        </div>
      )}
      <div>
        <footer className="footer" style={{ textAlign: 'center' }}>
          <Row className="align-items-center justify-content-between">
            <Col xl="6" className="d-flex justify-content-start">
              <div style={{ marginLeft: '10px', fontWeight: 'bold' }} className="copyright text-center text-xl-left text-muted">
                Copyright© {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href={`${process.env.PUBLIC_URL}/TermsCondition.html`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Svasti Innovation Labs Pvt Ltd
                </a>
              </div>

            </Col>

            <Col xl="6" className="d-flex justify-content-end social-media-links">
              {/* <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>Follow us on</div> */}
              <a href="http://instagram.com/" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={32} style={{ color: 'red', marginRight: '10px' }} />
              </a>
              <a href="http://facebook.com/" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={32} style={{ color: 'blue', marginRight: '10px' }} />
              </a>
              <a href="http://youtube.com/" target="_blank" rel="noopener noreferrer">
                <FaYoutube size={32} style={{ color: 'red', marginRight: '10px' }} />
              </a>
              <a href="http://twitter.com/" target="_blank" rel="noopener noreferrer">
                <FaTwitter size={32} style={{ color: 'blue' }} />
              </a>
            </Col>
          </Row>
          <div style={{ marginLeft: '10px', fontWeight: 'bold', cursor: 'pointer', textAlign: 'left' }}>
            <a
              href={`${process.env.PUBLIC_URL}/privacy_policy.html`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'blue', textDecoration: 'none' }}
            >
              Privacy Policy
            </a>
          </div>

        </footer>
      </div>
    </div>
  );
}

export default LandingPage;

