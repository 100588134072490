import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Try to get user data from localStorage on initial load
  const storedUserData = JSON.parse(localStorage.getItem('userData')) || {};
  const [userData, setUserData] = useState(storedUserData);

  const storedUserLoginCode = JSON.parse(localStorage.getItem('userLoginCode')) || {};
  const [userLoginCode, setUserLoginCode] = useState(storedUserLoginCode);

  const storedUserLoginName = JSON.parse(localStorage.getItem('userLoginName')) || {};
  const [userLoginName, setUsername] = useState(storedUserLoginName);

  const storedInstbasic = JSON.parse(localStorage.getItem('instbasic')) || {};
  const [instbasic, setInstbasic] = useState(storedInstbasic);


  useEffect(() => {
    localStorage.setItem('userData', JSON.stringify(userData));
    localStorage.setItem('userLoginCode', JSON.stringify(userLoginCode));
    localStorage.setItem('userLoginName', JSON.stringify(userLoginName));
    localStorage.setItem('instbasic', JSON.stringify(instbasic));
   
  }, [userData, userLoginCode,userLoginName,instbasic]);

  const setAuthData = (data) => {
    setUserData({ ...data });
   
  };

  const logout = () => {
    setUserData({});
    setUserLoginCode({});
    setUsername({});
    setInstbasic({});
  };

  return (
    <AuthContext.Provider value={{ userData, setAuthData, userLoginCode, setUserLoginCode,userLoginName ,setUsername,instbasic, setInstbasic, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
