import React, { useEffect, createContext, useContext, useState } from "react";
import { localUrl } from '../examples/Constant';
// Create a context
const ApiUrlContext = createContext();

// Create a context provider component
export const ApiUrlProvider = ({ children }) => {
  const storedUrl = JSON.parse(localStorage.getItem('apiUrl')) || null;
  const [apiUrl, setApiUrl] = useState(storedUrl ||localUrl);

  const setApiUrlContext = (newApiUrl) => {
    setApiUrl(newApiUrl);
  };

  useEffect(() => {
    // Save API URL to localStorage whenever it changes
    localStorage.setItem('apiUrl', JSON.stringify(apiUrl));
  }, [apiUrl]);

  return (
    <ApiUrlContext.Provider value={{ apiUrl, setApiUrlContext }}>
      {children}
    </ApiUrlContext.Provider>
  );
};

// Create a custom hook to use the context
export const useApiUrl = () => {
  const context = useContext(ApiUrlContext);
  if (!context) {
    throw new Error("useApiUrl must be used within an ApiUrlProvider");
  }
  return context;
};
