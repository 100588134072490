import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { DialogActions, DialogContent } from "@mui/material";
import Button from 'react-bootstrap/Button';
import { FaTimes } from 'react-icons/fa';

const LogoutConfirmation = ({ isOpen, toggle }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} centered>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1 style={{ borderBottom: "1px solid #ddd", textAlign: "center", marginBottom: "20px", flex: '1' }}>
            Are you sure?
          </h1>
         
        </div>
        <DialogContent style={{ padding: "0", textAlign: 'center', fontSize: "18px" }}>
        You will be logged out. Please confirm.
        </DialogContent>
        <div style={{ padding: "13px" }}></div>
        <DialogActions style={{ padding: "0", justifyContent: 'center' }}>
          <Button onClick={toggle} variant="success">
            Cancel
          </Button>
          <Button onClick={handleLogout} variant="danger">
            Confirm
          </Button>
        </DialogActions>
      </Modal>
    </>
  );
};

export default LogoutConfirmation;
