import { useState, useEffect } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import LogoutConfirmation from "views/examples/LogoutConfirmation"
import {
  Navbar,
  Collapse,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import axios from 'axios';
import { baseUrl } from "views/examples/Constant";
import { useAuth } from "views/examples/AuthContext";
import { useApiUrl } from "views/examples/ApiUrlContext";

const Sidebar = (props) => {
  const { userData } = useAuth();
  const { userLoginCode } = useAuth();
  const { apiUrl } = useApiUrl();
  const [instPlan, setInstPlan] = useState('');
  const [collapseOpen, setCollapseOpen] = useState();
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [instituteInfo, setInstituteInfo] = useState({ name: "", logoUrl: "" });
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  useEffect(() => {
    // Fetch institute name and logo URL when the component mounts
    fetchInstituteInfo();
    fetchInstiutePlans();
  }, []);

  const fetchInstiutePlans = async () => {
    try {
      const bearerToken = userData.accessToken;
      const response = await axios.get(
        `${apiUrl}/iplan/code/${userLoginCode.name}`,
        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setInstPlan(response.data.plan);
    } catch (error) {
      console.error("Error fetching institute plans:", error);

    }
  };

  const fetchInstituteInfo = async () => {
    try {
      const bearerToken = userData.accessToken;
      const response = await fetch(`${apiUrl}/iBasic/code/${userLoginCode.name}`,
        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
        });
      if (response.ok) {
        const data = await response.json();
        setInstituteInfo({ name: data.name, logoUrl: baseUrl + data.logo });
      } else {

      }
    } catch (error) {

    }
  };

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      // Check if the route name is "Suggested Candidate" and instPlan is not equal to '1'
      if (prop.name === "Suggested Candidate" && instPlan !== '1') {
        return (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              style={{ textAlign: "center" }}
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      }
      // Other conditions for rendering links
      else if (prop.name !== "SignUp" && prop.name !== "Logout") {
        return (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              style={{ textAlign: "center" }}
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      } else if (prop.name === "Logout") {
        // Render Logout link with onClick to open the confirmation modal
        return (
          <NavItem key={key} onClick={() => setShowLogoutConfirmation(true)}>
            <NavLink style={{ textAlign: "center" }}>
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      }
      return null;
    });
  };


  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  // Colors
  var colors = {
    darkblue: {
      100: "#cfd8e6",
      200: "#a2adc6",
      300: "#7573a6",
      400: "#494a86",
      500: "#1c1e66",
      600: "#171a54",
      620: "#101451",
      650: "#0f1241",
      700: "#121440",
      750: "#0e1135",
      800: "#0d1030",
      900: "#080b1f",
    }

  };

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-dark"
      style={{ backgroundColor: colors.darkblue[650] }}
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {instituteInfo.logoUrl && (

          <div style={{
            width: "120px", height: "120px", borderRadius: "50%", overflow: "hidden", marginInline: "auto",
            display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white"
          }}>
            <img src={instituteInfo.logoUrl} alt="Institute Logo" className="navbar-brand-img" style={{ width: "100%", height: "100%" }} />
          </div>

        )}
        <div style={{ justifyContent: "center", alignItems: "center", fontWeight: "bold", color: "white", marginTop: "10px", marginLeft: "auto", marginRight: "auto" }}>
          {instituteInfo.name}
        </div>

        <div style={{ textAlign: "center", fontWeight: "bold", color: "white" }}>
          Institute UID :{userLoginCode.name}
        </div>

        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>

          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
          {showLogoutConfirmation && (
            <LogoutConfirmation isOpen={showLogoutConfirmation} toggle={() => setShowLogoutConfirmation(false)} />
          )}
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;

