import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { Switch } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ChangePasswordModal from "../examples/ChangePasswordModal"
import {
  faDownload,
  faBellSlash,
  faInfoCircle,
  faTrash,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import "./Prod.css";
import { useApiUrl } from "views/examples/ApiUrlContext";
import { useAuth } from "views/examples/AuthContext";
import MySnackbar from 'views/examples/Snackbar'
import useUtil from "views/examples/Util"
import { baseUrl } from "views/examples/Constant";
import Topbar from "../examples/TopBar"

const Settings = () => {
  const { apiUrl } = useApiUrl();
  const { userData } = useAuth();
  const { userLoginCode } = useAuth();
  const { instbasic } = useAuth();
  const { userLoginName } = useAuth();
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const [isSchoolIdDialogOpen, setIsSchoolIdDialogOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [accountSummary, setAccountSummary] = useState([]);
  const [basicinstitutedata, setBasicinstitutedata] = useState({});
  const [isAccountSummaryModalOpen, setIsAccountSummaryModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarBgColor, setSnackbarBgColor] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);
  const [instituteInfo, setInstituteInfo] = useState({ name: "", logoUrl: "" });
  const { instPlan } = useAuth();
  const navigate = useNavigate();


  useEffect(() => {
    if (
      !userData ||
      userData === undefined ||
      userData === '' ||
      (typeof userData === 'object' && Object.keys(userData).length === 0)
    ) {
      navigate('/auth/login');
    }
  }, [userData, navigate]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDeleteConfirmationOpen = () => {
    setIsDeleteConfirmationOpen(true);
  };


  const handleDeleteConfirmationClose = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const handleAccountSummaryModalOpen = () => {
    setIsAccountSummaryModalOpen(true);
  };

  const handleAccountSummaryModalClose = () => {
    setIsAccountSummaryModalOpen(false);
  };

  const handleDeleteAccount = async () => {
    try {
      const bearerToken = userData.accessToken;
      const response = await axios.put(`${apiUrl}/auth/update/${userLoginName}`, {

        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },

      });
      const data = response.data;
      if (data.cd === 0) {
        setSnackbarMessage('Account deleted successfully');
        setSnackbarBgColor('green');
        setIsSuccess(true);
        setSnackbarOpen(true);
        logout();
      }
      else if (data.cd === 1) {
        setSnackbarMessage(data.description);
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);

      } else {
        setSnackbarMessage('Error deleting account, please contact the system administrator.');
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Error deleting account, please contact the system administrator.');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
    }
    setIsDeleteConfirmationOpen(false);
  };

  useEffect(() => {
    const getAccountSummary = async () => {
      try {
        const bearerToken = userData.accessToken;
        const response = await axios.get(`${apiUrl}/ipayment/accountSummary/${userLoginCode.name}`, {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.status == 200) {
          setAccountSummary(response.data);
        } else {

        }
      } catch (error) {

      }
    };

    getAccountSummary();
  }, []);


  const logout = () => {
    window.location.href = "/auth/login";
  };
  useEffect(() => {
    fetchInstituteInfo();
  }, []);
  const fetchInstituteInfo = async () => {
    try {
      const bearerToken = userData.accessToken;
      const response = await fetch(`${apiUrl}/iBasic/code/${userLoginCode.name}`,
        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
        });
      if (response.ok) {
        const data = await response.json();
        setInstituteInfo({ name: data.name, logoUrl: baseUrl + data.logo });
        setBasicinstitutedata(data);
        console.log(data);
      } else {

      }
    } catch (error) {

    }
  };

  const handleSchoolIdDownload = () => {
    const doc = new jsPDF();

    // Add border
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 15; // Margin for the border
    const padding = 10; // Padding between text and border
    const sectionSpacing = 20; // Additional spacing between sections

    doc.setLineWidth(0.5);
    doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);

    // Title
    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 0, 255); // Set text color to blue (RGB: 0, 0, 255)
    doc.text('INSTITUTE ID', pageWidth / 2, margin + padding + 10, { align: 'center' });

    // Name and Address of the College
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 0, 255); // Set text color to blue for bold text
    doc.text('INSTITUTE DETAILS:', margin + padding, margin + padding + 30);

    // doc.setFontSize(11);
    // doc.setFont('helvetica', 'normal');
    // doc.setTextColor(0, 0, 0); // Set text color to black for normal text
    // const collegeName = `Name: ${basicinstitutedata.name}`;
    // doc.text(collegeName, margin + padding, margin + padding + 40, { maxWidth: pageWidth - 2 * margin - padding * 2 });

    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');
    doc.setTextColor(0, 0, 0); // Set text color to black for normal text
    const collegeName = `Name: ${basicinstitutedata.name}`;
    const instituteId = `ID: ${basicinstitutedata.i_code}`;

    // Display Name and ID
    doc.text(collegeName, margin + padding, margin + padding + 40, { maxWidth: pageWidth - 2 * margin - padding * 2 });
    doc.text(instituteId, margin + padding, margin + padding + 50, { maxWidth: pageWidth - 2 * margin - padding * 2 });

    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 0, 255); // Set text color to blue for bold text
    doc.text('Address:', margin + padding, margin + padding + 60);

    doc.setFont('helvetica', 'normal');
    doc.setTextColor(0, 0, 0); // Set text color to black for normal text
    const addressLines = [
      `City: ${basicinstitutedata.city}`,
      `District: ${basicinstitutedata.dist}`,
      `State: ${basicinstitutedata.state}`,
      `Pin code: ${basicinstitutedata.pin}`,
      `Phone No: ${basicinstitutedata.phone}`,
      `E-Mail: ${basicinstitutedata.email}`,
      `Website: ${basicinstitutedata.website}`
    ];
    addressLines.forEach((line, index) => {
      doc.text(line, margin + padding, margin + padding + 70 + (index * 8), { maxWidth: pageWidth - 2 * margin - padding * 2 });
    });

    const pdfData = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfData);
    window.open(pdfUrl);
  };


  const handleProfileDownloadConfirm = () => {
    const doc = new jsPDF();

    // Add border
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 15; // Margin for the border
    const padding = 10; // Padding between text and border
    const sectionSpacing = 20; // Additional spacing between sections

    doc.setLineWidth(0.5);
    doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);

    // Title
    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 0, 255); // Set text color to blue (RGB: 0, 0, 255)
    doc.text('COLLEGE PROFILE', pageWidth / 2, margin + padding + 10, { align: 'center' });

    // Name and Address of the College
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 0, 255); // Set text color to blue for bold text
    doc.text('NAME AND ADDRESS OF THE COLLEGE:', margin + padding, margin + padding + 30);

    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');
    doc.setTextColor(0, 0, 0); // Set text color to black for normal text
    const collegeName = `Name: ${basicinstitutedata.name}`;
    doc.text(collegeName, margin + padding, margin + padding + 40, { maxWidth: pageWidth - 2 * margin - padding * 2 });

    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 0, 255); // Set text color to blue for bold text
    doc.text('Address:', margin + padding, margin + padding + 60);

    doc.setFont('helvetica', 'normal');
    doc.setTextColor(0, 0, 0); // Set text color to black for normal text
    const addressLines = [
      `City: ${basicinstitutedata.city}`,
      `District: ${basicinstitutedata.dist}`,
      `State: ${basicinstitutedata.state}`,
      `Pin code: ${basicinstitutedata.pin}`,
      `Phone No: ${basicinstitutedata.phone}`,
      `E-Mail: ${basicinstitutedata.email}`,
      `Website: ${basicinstitutedata.website}`
    ];
    addressLines.forEach((line, index) => {
      doc.text(line, margin + padding, margin + padding + 70 + (index * 8), { maxWidth: pageWidth - 2 * margin - padding * 2 });
    });

    // Add space before Principal section
    const addressEndY = margin + padding + 60 + (addressLines.length * 8) + sectionSpacing;

    // Principal details
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 0, 255); // Set text color to blue for bold text
    doc.text('Principal Contact No:', margin + padding, addressEndY);

    doc.setFont('helvetica', 'normal');
    doc.setTextColor(0, 0, 0); // Set text color to black for normal text
    doc.text(`${basicinstitutedata.princiNo}`, margin + padding, addressEndY + 8, { maxWidth: pageWidth - 2 * margin - padding * 2 });

    // Type of Institution
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 0, 255); // Set text color to blue for bold text
    doc.text('Type of Institution:', margin + padding, addressEndY + 30);

    doc.setFont('helvetica', 'normal');
    doc.setTextColor(0, 0, 0); // Set text color to black for normal text
    doc.text(`${basicinstitutedata.afflTypeVal}`, margin + padding, addressEndY + 38, { maxWidth: pageWidth - 2 * margin - padding * 2 });

    // Date of Establishment of the College
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 0, 255); // Set text color to blue for bold text
    doc.text('Year of establishment of the college:', margin + padding, addressEndY + 58);
    doc.setFont('helvetica', 'normal');
    doc.setTextColor(0, 0, 0); // Reset text color to black for normal text
    doc.text(`${basicinstitutedata.estYear}`, margin + padding, addressEndY + 66, { maxWidth: pageWidth - 2 * margin - padding * 2 });

    // Create Blob from the PDF and open it in a new window
    const pdfData = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfData);
    window.open(pdfUrl);
  };


  const toggleChangePasswordModal = () => {
    setChangePasswordModalOpen(!isChangePasswordModalOpen);
  };


  const buttonStyle = {
    textDecoration: "none",
    cursor: "pointer",
    margin: "5px",
  };


  const handleMuteToggle = () => {
    setIsMuted((prevIsMuted) => !prevIsMuted);
    // Add logic to handle mute/unmute action based on the value of isMuted
    if (isMuted) {

    } else {

    }
  };

  const handleUpdatenotification = async () => {

    try {
      const bearerToken = userData.accessToken;
      const requestData = {
        notification: isMuted,
        i_code: userLoginCode.name,
      };

      const response = await axios.post(
        `${apiUrl}/iBasic/priv`,
        requestData,
        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.get('exception') === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.get('exception') === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }

      const data = await response.json();

      if (data.cd === 1) {
        setSnackbarMessage('Update failed. Please try again.');
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Updated successfully!');
        setSnackbarBgColor('green');
    setIsSuccess(true);
    setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('An unexpected error occurred. Please try again.');
      setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
    }
  };



  return (
    <>

      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh", margin: "5px" }}
      >
        <Topbar />
        <div
          className="mt-3 shadow-lg "

          style={{
            backgroundColor: '#dbd7d7',
            border: "1px solid #ced4da",
            borderRadius: "10px",
          }}
        >


          {/* Profile Download Button */}
          <div className="p-2">
            <Button
              onClick={handleProfileDownloadConfirm}
              style={buttonStyle}

            >
              <FontAwesomeIcon icon={faDownload} /> Download Institute Profile
            </Button>
          </div>


          {instPlan !== "1" && (
            <div className="p-2">
              <Button
                onClick={() => handleSchoolIdDownload()}
                style={buttonStyle}
              >
                <FontAwesomeIcon icon={faDownload} /> Download Institute ID
              </Button>
            </div>
          )}


          {/* Mute and Unmute Notification Button */}
          <div className="p-2 d-flex align-items-center">
            <Button
              onClick={() => handleUpdatenotification()}
              style={buttonStyle}

            >
              <FontAwesomeIcon icon={faBellSlash} /> Mute And UnMute Notification
            </Button>
            <div className="ms-3">

              <Switch
                checked={isMuted}
                onChange={handleMuteToggle}
                inputProps={{ "aria-label": "Mute And Unmute Notification" }}
              />
            </div>
          </div>

          {/* Account Request Information Button */}
          <div className="p-2">
            <Button
              onClick={handleAccountSummaryModalOpen}
              style={buttonStyle}

            >
              <FontAwesomeIcon icon={faInfoCircle} /> Account Summary Request
            </Button>

            {isAccountSummaryModalOpen && (
              <div className="account-modal-overlay">
                <div className="account-modal-content">
                  <div className="account-modal-header">
                    <h2 className="modal-title"> Account Summary</h2>
                    <button className="cancel-button" onClick={handleAccountSummaryModalClose}>
                      &#x2715;
                    </button>
                  </div>
                  <div className="account-modal-body">

                    <table className="table" style={{ border: '1px solid #ddd' }}> {/* Adding border to the table */}
                      <thead style={{ backgroundColor: '#f0f0f0' }}> {/* Setting background color for the header */}
                        <tr>
                          <th style={{ borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>Amount(₹)</th> {/* Making header text bold */}
                          <th style={{ borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>Date</th>
                          <th style={{ borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>Status</th>
                          <th style={{ borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>Reference Number</th>

                        </tr>
                      </thead>
                      <tbody>
                        {accountSummary.map((summary) => (
                          <tr key={summary.id}>
                            <td>{summary.amount}</td>
                            <td>{summary.date}</td>
                            <td>{summary.comment}</td>
                            <td>{summary.referenceNumber}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>
                  <div className="account-button">

                    <Button onClick={handleAccountSummaryModalClose} variant="danger">
                      Close
                    </Button>

                  </div>
                </div>
              </div>

            )}

          </div>



          <div className="p-2">
            <Button onClick={toggleChangePasswordModal} style={buttonStyle} >
              <FontAwesomeIcon icon={faKey} /> Change Password
            </Button>
          </div>
          <ChangePasswordModal
            isOpen={isChangePasswordModalOpen}
            toggle={toggleChangePasswordModal}
          />


          <div className="p-2">
            <Button onClick={handleDeleteConfirmationOpen} style={buttonStyle}>
              <FontAwesomeIcon icon={faTrash} /> Delete Account
            </Button>
          </div>

          {/* Delete Account Confirmation Dialog */}
          <Dialog open={isDeleteConfirmationOpen} onClose={handleDeleteConfirmationClose}>
            <DialogTitle style={{ color: 'black' }}>Delete Account Confirmation</DialogTitle>
            <DialogContent>
              Are you sure you want to delete your account?
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteAccount} color="primary">
                Yes
              </Button>
              <Button onClick={handleDeleteConfirmationClose} variant="danger">
                No
              </Button>
            </DialogActions>
          </Dialog>

          <MySnackbar
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        snackbarMessage={snackbarMessage}
        snackbarBgColor={snackbarBgColor}
        isSuccess={isSuccess}
      />
        </div>

      </div>
    </>
  );
};

export default Settings;
