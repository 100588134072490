import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, styled, Typography, Menu, MenuItem } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate, useLocation } from 'react-router-dom';

const TopbarContainer = styled(AppBar)(({ theme }) => ({
  background: '#1B2547',
  borderRadius: '10px',
}));

const IconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
});

const Title = styled(Typography)({
  flexGrow: 1,
  textAlign: 'center',
  fontWeight: 'bold',
  color: 'white',
});

const Topbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  // Function to get the heading based on the current path
  const getHeading = () => {
    const headings = {
      '/admin/index': 'Dashboard',
      '/admin/Profile': 'Profile',
      '/admin/Recruitment': 'Recruitment',
      '/admin/myjobs': 'Received Application',
      '/admin/Suggestedcandidate': 'Suggested Candidate',
      '/admin/Employee': 'Manage Employee',
      '/admin/MyAccount': 'My Account',
      '/admin/Notification': 'Notifications',
      '/admin/Letters': 'Letters',
      '/admin/Settings': 'Account Settings',
      '/admin/Help': 'Help And Support',
      '/admin/About': 'About Us',
      '/admin/Feedbacks': 'My Feedbacks',
    };
    return headings[location.pathname] || '';
  };

  const handleSettingsClick = (event) => {
    setAnchorEl(event.currentTarget); // Open the menu
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the menu
  };

  const handleMenuClick = (path) => {
    navigate(path);
    handleClose(); // Close the menu after navigation
  };

  return (
    <TopbarContainer position="static">
      <Toolbar>
        <Title variant="h6">{getHeading()}</Title>
        <IconContainer>
          {/* <IconButton color="inherit" onClick={handleSettingsClick}>
            <SettingsIcon />
          </IconButton> */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleMenuClick('/admin/Notification')}>Notifications</MenuItem>
            <MenuItem onClick={() => handleMenuClick('/admin/Profile')}>Profile</MenuItem>
          </Menu>
        </IconContainer>
      </Toolbar>
    </TopbarContainer>
  );
};

export default Topbar;
