// MySnackbar.js
import React from 'react';
import { Snackbar, SnackbarContent,Button} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

// Conditional icon rendering
const getIcon = (isSuccess) => {
    return isSuccess ? (
        <CheckCircleIcon style={{ color: 'white', marginRight: '8px' }} />
    ) : (
        <ErrorIcon style={{ color: 'white', marginRight: '8px' }} />
    );
};

const MySnackbar = ({ snackbarOpen, handleSnackbarClose, snackbarMessage, snackbarBgColor, isSuccess }) => (
    <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
        <SnackbarContent
            style={{ backgroundColor: snackbarBgColor }}
            message={
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {getIcon(isSuccess)}
                    {snackbarMessage}
                </span>
            }
            action={[
                <Button key="got-it" color="inherit" size="small" onClick={handleSnackbarClose}>
                    Got it
                </Button>,
                <Button key="cancel" color="inherit" size="small" onClick={handleSnackbarClose}>
                    Cancel
                </Button>
            ]}
        />
    </Snackbar>
);

export default MySnackbar;
