import styled from "styled-components";

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  :before {
    content: "";
    position: absolute;
    background: #f3e7f3;
    height: 4px;
    width: 98%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

  :after {
    content: "";
    position: absolute;
    background: #4a154b;
    height: 4px;
    width: ${({ currentStep }) =>
      currentStep >= stepsData.length
        ? '98%'
        : `${(currentStep - 1) * (100 / (stepsData.length - 1))}%`};
    top: 50%;
    transition: width 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;



const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
  margin-right: 20px; /* Add some spacing between steps */
`;

const StepStyle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ completed }) => (completed ? "#4CAF50" : "#ffffff")};
  border: 3px solid ${({ completed }) => (completed ? "#4CAF50" : "#f3e7f3")};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${({ completed }) => (completed ? "#ffffff" : "#000000")};
`;

const StepCount = styled.span`
  font-size: 19px;
`;

const StepsLabelContainer = styled.div`
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StepLabel = styled.span`
  font-size: 19px;
  color: ${({ completed }) => (completed ? "#ffeb0f" : "#fff")};
`;

const stepsData = [
  {
    label: "Institution Profile",
    step: 1,
  },
  {
    label: "Additional Details",
    step: 2,
  },
  {
    label: "Upload Documents",
    step: 3,
  },
  {
    label: "Employment Strategies",
    step: 4,
  },
  {
    label: "Job Security",
    step: 5,
  },
  {
    label: "Plan",
    step: 6,
  },
];

export {
  StepContainer,
  StepWrapper,
  StepStyle,
  StepCount,
  StepsLabelContainer,
  StepLabel,
  stepsData,
};
