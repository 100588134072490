import React, { useState } from "react";
import { Button, } from "reactstrap";
import axios from "axios";
import "./suggestedjobs.css";
import MySnackbar from 'views/examples/Snackbar'
import { useAuth } from "views/examples/AuthContext";
import { useApiUrl } from "views/examples/ApiUrlContext";

const ForgotPasswordModal = ({ isOpen, toggle }) => {
  const { apiUrl } = useApiUrl();
  const { userData } = useAuth();
  const [otpRequested, setOtpRequested] = useState(false);
  const [resendTimeout, setResendTimeout] = useState(null);
  const [userId, setUserId] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarBgColor, setSnackbarBgColor] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleGetOTP = async () => {
    try {

      const response = await axios.post(
        `${apiUrl}/otp/generateotpi`,
        {
          login: userId
        },
        {
          headers: {

            'Content-Type': 'application/json'
          }
        }
      );

      // Handle response
      if (response.data.cd === 0) {
        // If response code is 0, it indicates success
        setSnackbarMessage(response.data.description);
        setSnackbarBgColor('green');
        setIsSuccess(true);
        setSnackbarOpen(true);
        setOtpRequested(true);
        const timeoutId = setTimeout(() => {
          setOtpRequested(false);
        }, 60000); // 60000 milliseconds = 1 minute
        setResendTimeout(timeoutId);
      } else {
        // If response code is not 0, it indicates an error
        setSnackbarMessage("Error while getting OTP:", response.data.description);
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
        // Handle error condition as needed
      }
    } catch (error) {

    }
  };



  const handleSubmit = async () => {
    // Check if passwords match
    if (newPassword !== confirmNewPassword) {
      setSnackbarMessage("Passwords do not match!");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/otp/verifyotpi`, // Adjust the API endpoint
        {
          otp: otp,
          password: newPassword,
          name: userId,
          newPassword: newPassword
        },
        {
          headers: {

            'Content-Type': 'application/json'
          }
        }
      );

      // Handle response if needed
      alert("Reset password response:", response.data);
    } catch (error) {

    }
  };

  return (
    <>
      {isOpen && (
        <div className="forgetpwd-modal-overlay">
          <div className="forgetpwd-modal-content">
            <div className="forgetpwd-modal-header">
              <h2 className="modal-title">Forgot Password</h2>
              <button className="cancel-button" onClick={toggle}>
                &#x2715;
              </button>
            </div>
            <div className="forgetpwd-modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="userId" style={{ fontWeight: 'bold' }}>
                    Email <span className="required-asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="userId"
                    placeholder="Enter Email"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                  />
                </div>
                {otpRequested && (
                  <div className="form-group">
                    <label htmlFor="otp" style={{ fontWeight: 'bold' }}>
                      Enter OTP <span className="required-asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="otp"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                )}
                {otpRequested && (
                  <>
                    <div className="form-group">
                      <label htmlFor="newPassword" style={{ fontWeight: 'bold' }}>
                        New Password <span className="required-asterisk">*</span>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="newPassword"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="confirmNewPassword" style={{ fontWeight: 'bold' }}>
                        Confirm New Password{' '}
                        <span className="required-asterisk">*</span>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="confirmNewPassword"
                        placeholder="Confirm new password"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                      />
                    </div>
                  </>
                )}
              </form>
              {otpRequested && (
                <div className="alert alert-success" role="alert">
                  OTP will be sent to the registered email address.
                </div>
              )}

            </div>
            <div className="forgetpwd-button">
              {!otpRequested && (
                <Button color="primary" onClick={handleGetOTP}>
                  Get OTP
                </Button>
              )}
              <Button color="danger" onClick={toggle}>
                Cancel
              </Button>
              {otpRequested && (
                <Button color="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </div>
          </div>
          <MySnackbar
            snackbarOpen={snackbarOpen}
            handleSnackbarClose={handleSnackbarClose}
            snackbarMessage={snackbarMessage}
            snackbarBgColor={snackbarBgColor}
            isSuccess={isSuccess}
          />
        </div>
      )}
    </>
  );
};
export default ForgotPasswordModal;
