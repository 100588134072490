import { Dialog, DialogActions, DialogContent } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useSharedvars } from 'views/examples/Sharedvars';
import "react-toastify/dist/ReactToastify.css";
import "./Prod.css";
import "./suggestedjobs.css";
import Button from "react-bootstrap/Button";
import { Row, Col } from 'react-bootstrap';
import MySnackbar from 'views/examples/Snackbar'
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import {
  FormGroup,
  Label,
  Form,
  Input,
} from "reactstrap";
import { useApiUrl } from "views/examples/ApiUrlContext";
import { useAuth } from "views/examples/AuthContext";
import useUtil from "views/examples/Util"
import { ClipLoader } from 'react-spinners';
import Topbar from "../examples/TopBar"

const OrganisationTable = () => {
  const { getDropdownData } = useSharedvars();
  const { apiUrl } = useApiUrl();
  const { userData } = useAuth();
  const { instbasic } = useAuth();
  const { userLoginCode } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [qualificationOptions, setqualificationOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedQualifications, setSelectedQualifications] = useState([]);
  const [roleoptions, setroleoptions] = useState([]);
  const [modeOption, setmodeoption] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedmode, setselectedMode] = useState(null);
  const [subjectoption, setsubjectoption] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [nonTeachingroleoptions, setNonteachingroleoptions] = useState([]);
  const [newItem, setNewItem] = useState({
    type: "",
    role: "",
    subject1: "",
    subject2: "",
    subject3: "",
    subject4: "",
    qual1: "",
    qual2: "",
    qual3: "",
    qual4: "",
    qual5: "",
    experience: "",
    gender: "",
    salaryFrom: "",
    salaryTo: "",
    date: "",
    status: "",
    mode: "",
    fulltime: null,
  });

  const [validationErrors, setValidationErrors] = useState({
    type: "",
    role: "",
    subject1: "",
    qual1: "",
    experience: "",
    gender: "",
    salaryFrom: "",
    salaryTo: "",
    date: "",
    status: "",
    mode: "",
    schdate: ""
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarBgColor, setSnackbarBgColor] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);

  const [searchQuery, setSearchQuery] = useState("");

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedColumnsData, setSelectedColumnsData] = useState([
    { Header: "Type", name: "typeVal", display: true },
    { Header: "Role", name: "roleVal", display: true },
    { Header: "Subject", name: "subject1Val", display: true },
    { Header: "Qualification", name: "qual1Val", display: true },
    { Header: "Experience", name: "experience", display: true },
    { Header: "Gender", name: "gender", display: true },
    { Header: "Min Salary", name: "salaryFrom", display: true },
    { Header: "Max Salary", name: "salaryTo", display: true },
    { Header: "Date", name: "schdate", display: true },
    { Header: "Status", name: "status", display: true },
  ]);

  const genders = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'No Preference', value: 'any' }
  ];

  const employeeOptions = [
    { value: "TE", label: "Teaching" },
    { value: "NT", label: "Non teaching" },
    { value: "AD", label: "Admin" },
    // Add more options as needed
  ];

  const [showColumnOptions, setShowColumnOptions] = useState(false);

  const statuses = [
    { value: 'Posted', label: 'Posted' },
    { value: 'Withdraw', label: 'Withdraw' },
    // Add more options as needed
  ];
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !userData ||
      userData === undefined ||
      userData === '' ||
      (typeof userData === 'object' && Object.keys(userData).length === 0)
    ) {
      navigate('/auth/login');
    }
  }, [userData, navigate]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleStatusChange = (selectedOption) => {
    setNewItem({
      ...newItem,
      status: selectedOption.value,
    });
    setValidationErrors({
      ...validationErrors,
      status: '',
    });
  };
  const handleQualificationsChange = (selectedOptions) => {
    if (selectedOptions.length <= 5) {
      const newQualifications = selectedOptions.map((option) => option.value);
      setSelectedQualifications(newQualifications);
      if (newQualifications.length > 5) {
        newQualifications.splice(5);
      }
      setNewItem({
        ...newItem,
        qual1: newQualifications[0] || '',
        qual2: newQualifications[1] || '',
        qual3: newQualifications[2] || '',
        qual4: newQualifications[3] || '',
        qual5: newQualifications[4] || '',
      });
      setValidationErrors({ ...validationErrors, qual1: "" });
    }
  };

  const handleSubjectChange = (selectedOptions) => {
    if (selectedOptions.length <= 4) {
      const newsubjects = selectedOptions.map((option) => option.value);
      setSelectedSubjects(newsubjects);
      if (newsubjects.length > 4) {
        newsubjects.splice(4);
      }
      setNewItem({
        ...newItem,
        subject1: newsubjects[0] || '',
        subject2: newsubjects[1] || '',
        subject3: newsubjects[2] || '',
        subject4: newsubjects[3] || '',

      });
      setValidationErrors({ ...validationErrors, subject1: "" });
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Fetch courses from your API endpoint
    const fetNonTeachingchroleoptions = async () => {
      try {
        const response = getDropdownData('RN');
        const mappedOptions = response.map((option) => ({
          code: option.code,
          value: option.value,
          label: `${option.code} - ${option.value}`,
        }));
        setNonteachingroleoptions(mappedOptions);
      } catch (error) {

      }
    };

    fetNonTeachingchroleoptions();
  }, [getDropdownData]);

  const fetchData = async () => {
    try {
      const bearerToken = userData.accessToken;
      const response = await fetch(`${apiUrl}/jobpost/icode/${userLoginCode.name}`,
        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json', // Adjust the content type if needed
          },
        })
      const data = await response.json();

      setData(data);
      setInitialData(data);
      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.Exception === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.Exception === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }
    } catch (error) {

    }
  };

  const today = new Date().toISOString().split('T')[0];

  const handleDateChange = (e) => {
    const dateValue = e.target.value;
    setNewItem({ ...newItem, schdate: dateValue });
    setValidationErrors({ ...validationErrors, schdate: "" });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page when rows per page changes
  };

  // const filteredData = Array.isArray(data)
  //   ? data.filter((item) => {
  //     return Object.values(item).some((value) =>
  //       String(value).toLowerCase().includes(searchQuery.toLowerCase())
  //     );
  //   })
  //   : [];

  const filteredData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleRowClick = (itemId) => {
    setSelectedItemId((prevSelected) =>
      prevSelected === itemId ? null : itemId
    );
  };
  const fulltimeOptions = [
    { value: 'FT', label: 'Full Time' },
    { value: 'PT', label: 'Part Time' },
  ];

  const handleFulltimeChange = (selectedOption) => {
    setNewItem({ ...newItem, fulltime: selectedOption ? selectedOption.value : null });
    setValidationErrors({ ...validationErrors, fulltime: "" });
  };
  const handleDelete = async () => {
    if (!selectedItemId) {
      setSnackbarMessage("Please select a row to delete.");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }

    setDeleteConfirmationOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const bearerToken = userData.accessToken;
      const response = await axios.delete(
        `${apiUrl}/jobpost/${selectedItemId}`,

        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json', // Adjust the content type if needed
          },
        })
      setSelectedItemId(null);
      fetchData();
      setDeleteConfirmationOpen(false);
      setSnackbarMessage("Jobpost Deleted Successfully.");
      setSnackbarBgColor('green');
      setIsSuccess(true);
      setSnackbarOpen(true);
      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.Exception === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.Exception === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }
    } catch (error) {

    }
  };

  const cancelDelete = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleAdd = () => {
    setNewItem({
      type: "",
      role: "",
      subject1: "",
      subject2: "",
      subject3: "",
      subject4: "",
      qual1: "",
      qual2: "",
      qual3: "",
      qual4: "",
      qual5: "",
      experience: "",
      gender: "",
      salaryFrom: "",
      salaryTo: "",
      schdate: "",
      mode: "",
      fulltime: ""
    });


    //setImagePreviewUrl("");
    setShowAddModal(true);
  };


  const handleUpdate = async () => {
    if (!selectedItemId) {
      setSnackbarMessage("Please select a row to update.");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }

    try {
      const selectedItem = data.find((item) => item.id === selectedItemId);
      if (selectedItem) {
        setNewItem({
          ...selectedItem,
        });

        setSelectedSubjects([selectedItem.subject1, selectedItem.subject2,
        selectedItem.subject3, selectedItem.subject4]);

        setSelectedQualifications([selectedItem.qual1, selectedItem.qual2,
        selectedItem.qual3, selectedItem.qual4, selectedItem.qual5]);


        setShowUpdateModal(true);
      }
    } catch (error) {

    }
  };

  const validateFields = () => {
    let errors = {};
    const isNumeric = (value) => !isNaN(value) && !isNaN(parseFloat(value));

    if (!newItem.type) errors.type = "Please select employee type.";
    if (!newItem.role) errors.role = "Please fill in this field.";
    if (!newItem.subject1) errors.subject1 = "Please fill in this field.";
    if (!newItem.qual1) errors.qual1 = "Please fill in this field.";
    if (!isNumeric(newItem.experience)) errors.experience = "Experience is required and must be numeric.";
    if (!newItem.gender) errors.gender = "Please fill in this field.";
    if (!isNumeric(newItem.salaryFrom)) errors.salaryFrom = "Maximum salary is required and must be numeric.";
    if (!isNumeric(newItem.salaryTo)) errors.salaryTo = "Minimum salary is required and must be numeric.";
    if (parseFloat(newItem.salaryFrom) > parseFloat(newItem.salaryTo)) {
      errors.salaryTo = "Maximum salary must be greater than or equal to Minimum salary.";
    }
    if (!newItem.schdate) errors.schdate = "Please fill in this field.";
    if (!newItem.mode) errors.mode = "Please fill in this field.";
    if (!newItem.fulltime) errors.fulltime = "Please fill in this field.";

    setValidationErrors(errors);
    return errors;
  };


  const handleSave = async () => {
    try {
      setLoading(true);
      let bearerToken = userData.accessToken;
      const fieldErrors = validateFields();
      setValidationErrors(fieldErrors);
      if (Object.keys(fieldErrors).length > 0) {
        toast.warn("Please fill all required fields");
        setLoading(false);
        return;
      }


      const recruitment = {
        id: selectedItemId,
        code: newItem.code,
        iCode: userLoginCode.name,
        type: newItem.type,
        role: newItem.role,
        subject1: newItem.subject1,
        subject2: newItem.subject2,
        subject3: newItem.subject3,
        subject4: newItem.subject4,
        qual1: newItem.qual1,
        qual2: newItem.qual2,
        qual3: newItem.qual3,
        qual4: newItem.qual4,
        qual5: newItem.qual5,
        experience: newItem.experience,
        gender: newItem.gender,
        salaryFrom: newItem.salaryFrom,
        salaryTo: newItem.salaryTo,
        schdate: newItem.schdate,
        status: newItem.status,
        mode: newItem.mode,
        fulltime: newItem.fulltime
      };

      const config = {
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
      };

      if (showAddModal) {
        const response = await axios.post(`${apiUrl}/jobpost`, recruitment, config);
        if (response.data.cd == 1) {
          setSnackbarMessage(response.data.description);
          setSnackbarBgColor('red');
          setIsSuccess(false);
          setSnackbarOpen(true);
        }
        else {
          setSnackbarMessage("Jobpost Created Successfully.");
          setSnackbarBgColor('green');
          setIsSuccess(true);
          setSnackbarOpen(true);
        }

      } else if (showUpdateModal) {
        const response = await axios.put(`${apiUrl}/jobpost/${selectedItemId}`, recruitment, config);
        if (response.data.cd == 1) {
          setSnackbarMessage(response.data.description);
          setSnackbarBgColor('red');
          setIsSuccess(false);
          setSnackbarOpen(true);
        }
        else {
          setSnackbarMessage("Jobpost Updated Successfully.");
          setSnackbarBgColor('green');
          setIsSuccess(true);
          setSnackbarOpen(true);
        }
        if (response && (response.status === 403 || response.status === 400)) {
          const headers = response.headers;
          if (headers && headers.get('exception') === 'SignatureException') {
            await useUtil.logout();
            // clear shared Pref & perform logout
          } else if (headers && headers.get('exception') === 'ExpiredJwtException') {
            bearerToken = await useUtil.refreshToken();
            // Use the newBearerToken as needed
          }
        }
      }

      setShowAddModal(false);
      setShowUpdateModal(false);
      setNewItem({
        type: "",
        code: "",
        role: "",
        subject1: "",
        subject2: "",
        subject3: "",
        subject4: "",
        qual1: "",
        qual2: "",
        qual3: "",
        qual4: "",
        qual5: "",
        experience: "",
        gender: "",
        salaryFrom: "",
        salaryTo: "",
        schdate: "",
        status: "",
        mode: "",
        fulltime: ""
      });
      setValidationErrors("");
      setSelectedSubjects("");
      setSelectedQualifications("");
      fetchData();

    } catch (error) {
      setShowAddModal(false);
      setShowUpdateModal(false);
      setValidationErrors('');
      setSelectedSubjects("");
      setSelectedQualifications("");
      setSnackbarMessage("Some Issue Pls Check.");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);

    }
    finally {
      setLoading(false);
    }
  };


  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();

    // Check if the search query is empty
    if (query.trim() === '') {
      setSearchQuery('');
      setData(initialData); // Reset data to initial unfiltered data
      return;
    }

    // Filter data based on the search query
    const filteredData = Array.isArray(data)
      ? data.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === 'string' && value.toLowerCase().includes(query)
        )
      )
      : [];

    setSearchQuery(query);
    setData(filteredData);
  };
  const handleColumnToggle = (columnName) => {
    setSelectedColumnsData((prevColumns) =>
      prevColumns.map((col) =>
        col.Header === columnName ? { ...col, display: !col.display } : col
      )
    );
  };

  useEffect(() => {
    // Fetch courses from your API endpoint
    const fetchroleoptions = async () => {
      try {
        const response = getDropdownData('RL');
        const mappedOptions = response.map((option) => ({
          value: option.code,
          label: option.value,
        }))
        setroleoptions(mappedOptions);
      } catch (error) {

      }
    };

    fetchroleoptions();
  }, [getDropdownData]);

  useEffect(() => {
    // Fetch courses from your API endpoint
    const fetchmodeptions = async () => {
      try {
        const response = getDropdownData('M');
        const mappedOptions = response.map((option) => ({
          code: option.code,
          value: option.value,
          label: option.value,
        }));

        setmodeoption(mappedOptions);
      } catch (error) {

      }
    };

    fetchmodeptions();
  }, [getDropdownData]);



  useEffect(() => {
    // Fetch courses from your API endpoint
    const fetchsubjectoptions = async () => {
      try {
        const response = getDropdownData('SB');
        const mappedOptions = response.map((option) => ({
          value: option.code,
          label: option.value,
        }));
        setsubjectoption(mappedOptions);
      } catch (error) {

      }
    };
    fetchsubjectoptions();
  }, [getDropdownData]);


  useEffect(() => {
    // Fetch courses from your API endpoint
    const fetchqualificationOptions = async () => {
      try {
        const response = getDropdownData('QA');
        const mappedOptions = response.map((option) => ({
          value: option.code,
          label: option.value,
        }));
        setqualificationOptions(mappedOptions);
      } catch (error) {

      }
    };
    fetchqualificationOptions();
  }, [getDropdownData]);

  const handleCloseModal = () => {
    setShowAddModal(false);
    setShowUpdateModal(false);
    setValidationErrors("");
    setSelectedSubjects("");
    setSelectedQualifications("");
    setNewItem({
      type: "",
      code: "",
      role: "",
      subject1: "",
      subject2: "",
      subject3: "",
      subject4: "",
      qual1: "",
      qual2: "",
      qual3: "",
      qual4: "",
      qual5: "",
      experience: "",
      gender: "",
      salaryFrom: "",
      salaryTo: "",
      schdate: "",
      status: "",
      mode: "",
      fulltime: ""
    });
  };


  const handleGenderChange = (selectedOption) => {
    setNewItem({
      ...newItem,
      gender: selectedOption ? selectedOption.value : null,  // Use null if nothing is selected
    });
    setValidationErrors({ ...validationErrors, gender: "" });
  };



  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Topbar />
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, textAlign: "center" }}>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: 10,
              }}
            >
              <div style={{ position: "relative", display: "inline-block" }}>
                <input
                  style={{
                    borderRadius: "5px",
                    padding: "5px 30px 5px 10px", /* Add extra padding on the right for the icon */
                  }}
                  type="text"
                  id="searchInput"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <span style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  pointerEvents: "none",
                }}>
                  <i className="fa fa-search" style={{ color: "#888" }}></i>
                </span>
              </div>

            </div>
            {showColumnOptions && (
              <div className="modal">
                <div className="column-options-modal">
                  <h2>Column Options</h2>
                  <div className="column-options">
                    {selectedColumnsData.map((col) => (
                      <label className="column-option-label" key={col.Header}>
                        <input
                          type="checkbox"
                          checked={col.display}
                          onChange={() => handleColumnToggle(col.Header)}
                        />
                        {col.Header}
                      </label>
                    ))}
                  </div>
                  <Button
                    variant="primary"
                    style={{ padding: "5px 10px" }}
                    onClick={() => setShowColumnOptions(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
              }}
            >
              <div>

                <Button onClick={handleAdd} variant="success">
                  Add Vacancy
                </Button>{" "}
                <Button onClick={handleUpdate} variant="warning">
                  Edit Vacancy
                </Button>{" "}
                <Button onClick={handleDelete} variant="danger">
                  Delete Vacancy
                </Button>{" "}

              </div>
              <Dialog open={deleteConfirmationOpen} onClose={cancelDelete}>
                <DialogContent>
                  Are you sure you want to delete?
                </DialogContent>
                <DialogActions>
                  <Button onClick={cancelDelete} variant="outline-success">
                    Cancel
                  </Button>
                  <Button onClick={confirmDelete} variant="outline-danger">
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            {showAddModal && (
              <div className="jobpost-modal-overlay">
                <div className="jobpost-modal-content">
                  <div className="jobpost-modal-header">
                    <h2 className="modal-title">Add Recruitment</h2>
                    <button className="cancel-button" onClick={handleCloseModal}>
                      &#x2715;
                    </button>
                  </div>
                  <div className="jobpost-modal-body">
                    <Form role="form">
                      <div
                      >
                        <Row>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <Label for="employeeInput" className="font-weight-bold">
                                Select Employee Type <span className="required-asterisk">*</span>
                              </Label>
                              <Select
                                name="employee"
                                id="employeeInput"
                                options={employeeOptions}

                                value={employeeOptions.find((option) => option.value === newItem.type)}
                                onChange={(selectedOption) => {
                                  setNewItem({ ...newItem, type: selectedOption.value });
                                  setSelectedEmployee(selectedOption);
                                  setValidationErrors({ ...validationErrors, type: "" });
                                }}
                                placeholder="Select employee type"
                                style={{
                                  borderColor: validationErrors.type ? "red" : "",
                                }}

                              />
                              {validationErrors.type && (
                                <div className="error-text" style={{ color: "red" }}>
                                  {validationErrors.type}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <Label for="roleInput" className="font-weight-bold">
                                Role <span className="required-asterisk">*</span>
                              </Label>
                              <Select
                                name="role"
                                id="roleInput"
                                value={roleoptions.find((option) => option.value === newItem.role)}
                                options={roleoptions}
                                onChange={(selectedOption) => {
                                  setNewItem({ ...newItem, role: selectedOption.value });
                                  setSelectedRole(selectedOption);
                                  setValidationErrors({ ...validationErrors, role: "" });
                                }}
                                placeholder="Select role"
                                style={{
                                  borderColor: validationErrors.role ? "red" : "",
                                }}
                              />
                              {validationErrors.role && (
                                <div className="error-text" style={{ color: "red" }}>
                                  {validationErrors.role}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>

                        <FormGroup className="mb-3">
                          <Label for="subjectsInput" className="font-weight-bold">
                            Subjects(Select maximum 4) <span className="required-asterisk">*</span>
                          </Label>
                          <Select
                            isMulti
                            name="subjects"
                            id="subjectsInput"
                            value={subjectoption.filter(option => selectedSubjects.includes(option.value))}

                            onChange={handleSubjectChange}
                            placeholder="Select Subjects (Max 4)"
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            maxMenuHeight={150}
                            isOptionDisabled={(option) => selectedSubjects.length >= 4 && !selectedSubjects.includes(option) && !option.isSelected}

                            options={subjectoption}
                          />
                          {validationErrors.subject1 && (
                            <div className="error-text" style={{ color: "red" }}>
                              {validationErrors.subject1}
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label className="font-weight-bold">Qualifications (Select maximum 5) <span className="required-asterisk">*</span></Label>
                          <Select
                            isMulti
                            name="qualifications"
                            // value={qualificationOptions.find(option => option.value === selectedQualifications)}
                            value={qualificationOptions.filter(option => selectedQualifications.includes(option.value))}
                            //value={selectedQualifications}
                            options={qualificationOptions}
                            onChange={handleQualificationsChange}
                            placeholder="Select Qualifications (Max 3)"
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            maxMenuHeight={150}
                            maxMenuWidth={300}
                            isOptionDisabled={(option) => selectedQualifications.length >= 5 && !selectedQualifications.map(qual => qual.value).includes(option.lebel)}
                          />
                          {validationErrors.qual1 && (
                            <div className="error-text" style={{ color: "red" }}>
                              {validationErrors.qual1}
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label for="experienceFromInput" className="font-weight-bold">
                            Minimum Experience In Years <span className="required-asterisk">*</span>
                          </Label>
                          <Input
                            type="text"
                            id="experienceFromInput"
                            placeholder="Enter Minimum Experience In Years"
                            value={newItem.experience}
                            onChange={(e) => {
                              // Allow only numeric values
                              const inputValue = e.target.value.replace(/\D/, '');

                              setNewItem({
                                ...newItem,
                                experience: inputValue,
                              });

                              // Clear validation error when the user starts typing
                              setValidationErrors({
                                ...validationErrors,
                                experience: "",
                              });
                            }}
                            // Apply red border if validation error exists
                            style={{
                              color: "black",
                              borderColor: validationErrors.experience ? "red" : "",
                            }}
                          />
                          {/* Display error message if validation error exists */}
                          {validationErrors.experience && (
                            <div className="error-text" style={{ color: "red" }}>
                              {validationErrors.experience}
                            </div>
                          )}
                        </FormGroup>
                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup className="mb-3">
                              <Label for="salaryFromInput" className="font-weight-bold">
                                Minimum Salary <span className="required-asterisk">*</span>
                              </Label>
                              <Input
                                type="text"
                                id="salaryFromInput"
                                placeholder="Enter Minimum Salary"
                                value={newItem.salaryFrom}
                                onChange={(e) => {
                                  const enteredValue = e.target.value;
                                  setNewItem({
                                    ...newItem,
                                    salaryFrom: enteredValue,
                                  });
                                  // Clear validation error when the user starts typing
                                  setValidationErrors({
                                    ...validationErrors,
                                    salaryFrom: "",
                                  });
                                }}
                                // Apply red border if validation error exists
                                style={{
                                  color: "black",
                                  borderColor: validationErrors.salaryFrom ? "red" : "",
                                }}
                              />
                              {/* Display error message if validation error exists */}
                              {validationErrors.salaryFrom && (
                                <div className="error-text" style={{ color: "red" }}>
                                  {validationErrors.salaryFrom}
                                </div>
                              )}
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup className="mb-3">
                              <Label for="salaryToInput" className="font-weight-bold">
                                Maximum Salary <span className="required-asterisk">*</span>
                              </Label>
                              <Input
                                type="text"
                                id="salaryToInput"
                                placeholder="Enter Maximum Salary"
                                value={newItem.salaryTo}
                                onChange={(e) => {
                                  const enteredValue = e.target.value;
                                  setNewItem({
                                    ...newItem,
                                    salaryTo: enteredValue,
                                  });
                                  // Clear validation error when the user starts typing
                                  setValidationErrors({
                                    ...validationErrors,
                                    salaryTo: "",
                                  });
                                }}
                                // Apply red border if validation error exists
                                style={{
                                  color: "black",
                                  borderColor: validationErrors.salaryTo ? "red" : "",
                                }}
                              />
                              {/* Display error message if validation error exists */}
                              {validationErrors.salaryTo && (
                                <div className="error-text" style={{ color: "red" }}>
                                  {validationErrors.salaryTo}
                                </div>
                              )}
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup className="mb-3">
                              <Label for="roleInput" className="font-weight-bold">
                                Job Mode <span className="required-asterisk">*</span>
                              </Label>
                              <Select
                                name="role"
                                id="roleInput"
                                value={newItem.mode ? modeOption.find((option) => option.code === newItem.mode) : null}
                                options={modeOption}
                                onChange={(selectedOption) => {
                                  setNewItem({ ...newItem, mode: selectedOption.code });
                                  setselectedMode(selectedOption);
                                  setValidationErrors({ ...validationErrors, mode: "" });
                                }}
                                placeholder="Select Mode"
                              />
                              {validationErrors.mode && (
                                <div className="error-text" style={{ color: "red" }}>
                                  {validationErrors.mode}
                                </div>
                              )}
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup className="mb-3">
                              <Label for="roleInput" className="font-weight-bold">
                                Job Type <span className="required-asterisk">*</span>
                              </Label>
                              <Select
                                options={fulltimeOptions}
                                value={fulltimeOptions.find((option) => option.value === newItem.fulltime)}
                                onChange={handleFulltimeChange}
                                placeholder="Select Job Type"
                              />
                              {validationErrors.fulltime && (
                                <div className="error-text" style={{ color: "red" }}>
                                  {validationErrors.fulltime}
                                </div>
                              )}
                            </FormGroup>
                          </div>
                        </div>
                        <Row>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <Label for="genderInput" className="font-weight-bold">
                                Gender Preferred <span className="required-asterisk">*</span>
                              </Label>
                              <Select
                                name="gender"
                                id="genderInput"
                                value={newItem.gender ? genders.find(option => option.value === newItem.gender) : null}   // Find the selected option based on newItem.gender
                                onChange={(selectedOption) => handleGenderChange(selectedOption)}
                                options={genders}
                                placeholder="Select Gender"
                              />
                              {validationErrors.gender && (
                                <div className="error-text" style={{ color: "red" }}>
                                  {validationErrors.gender}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <Label for="dateInput" className="font-weight-bold">
                                Scheduled Date <span className="required-asterisk">*</span>
                              </Label>
                              <Input
                                type="date"
                                name="date"
                                id="dateInput"
                                value={newItem.schdate ? newItem.schdate : null}
                                onChange={handleDateChange}
                                min={today}
                                style={{ color: "black", }}
                              />
                              {validationErrors.schdate && (
                                <div className="error-text" style={{ color: "red" }}>
                                  {validationErrors.schdate}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                    </Form>
                    {loading && (
                      <div className="spinner-container">
                        <ClipLoader size={50} color={"#123abc"} loading={loading} />
                      </div>
                    )}

                  </div>
                  <div className="jobpost-button">
                    <Button onClick={handleSave} disabled={loading} variant="success">
                      Publish
                    </Button>
                    <Button variant="danger" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </div>
                </div>
              </div>

            )}
            {showUpdateModal && (
              <div className="jobpost-modal-overlay">
                <div className="jobpost-modal-content">
                  <div className="jobpost-modal-header">
                    <h2 className="modal-title"> Update Recruitment</h2>
                    <button className="cancel-button" onClick={handleCloseModal}>
                      &#x2715;
                    </button>
                  </div>
                  <div className="jobpost-modal-body">
                    <Form role="form">
                      <div
                      >


                        <FormGroup className="mb-3">
                          <Label for="roleInput" className="font-weight-bold">
                            Role <span className="required-asterisk">*</span>
                          </Label>
                          <Select
                            name="role"
                            id="roleInput"
                            value={roleoptions.find((option) => option.value === newItem.role)}
                            options={roleoptions}
                            onChange={(selectedOption) => {
                              setNewItem({ ...newItem, role: selectedOption.value });
                              setSelectedRole(selectedOption);
                              setValidationErrors({ ...validationErrors, role: "" });
                            }}
                            placeholder="Select role"
                            style={{
                              borderColor: validationErrors.role ? "red" : "",
                            }}
                          />
                          {validationErrors.role && (
                            <div className="error-text" style={{ color: "red" }}>
                              {validationErrors.role}
                            </div>
                          )}
                        </FormGroup>

                        <FormGroup className="mb-3">
                          <Label for="subjectsInput" className="font-weight-bold">
                            Subjects(Select maximum 4) <span className="required-asterisk">*</span>
                          </Label>
                          <Select
                            isMulti
                            name="subjects"
                            id="subjectsInput"
                            value={subjectoption.filter(option => selectedSubjects.includes(option.value))}

                            onChange={handleSubjectChange}
                            placeholder="Select Subjects (Max 4)"
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            maxMenuHeight={150}
                            isOptionDisabled={(option) => selectedSubjects.length >= 4 && !selectedSubjects.includes(option) && !option.isSelected}

                            options={subjectoption}
                          />
                          {validationErrors.subject1 && (
                            <div className="error-text" style={{ color: "red" }}>
                              {validationErrors.subject1}
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label className="font-weight-bold">Qualifications (Select maximum 5) <span className="required-asterisk">*</span></Label>
                          <Select
                            isMulti
                            name="qualifications"
                            // value={qualificationOptions.find(option => option.value === selectedQualifications)}
                            value={qualificationOptions.filter(option => selectedQualifications.includes(option.value))}
                            //value={selectedQualifications}
                            options={qualificationOptions}
                            onChange={handleQualificationsChange}
                            placeholder="Select Qualifications (Max 3)"
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            maxMenuHeight={150}
                            maxMenuWidth={300}
                            isOptionDisabled={(option) => selectedQualifications.length >= 5 && !selectedQualifications.map(qual => qual.value).includes(option.lebel)}
                          />
                          {validationErrors.qual1 && (
                            <div className="error-text" style={{ color: "red" }}>
                              {validationErrors.qual1}
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label for="experienceFromInput" className="font-weight-bold">
                            Minimum Experience In Years <span className="required-asterisk">*</span>
                          </Label>
                          <Input
                            type="text"
                            id="experienceFromInput"
                            placeholder="Enter Minimum Experience In Years"
                            value={newItem.experience}
                            onChange={(e) => {
                              // Allow only numeric values
                              const inputValue = e.target.value.replace(/\D/, '');

                              setNewItem({
                                ...newItem,
                                experience: inputValue,
                              });

                              // Clear validation error when the user starts typing
                              setValidationErrors({
                                ...validationErrors,
                                experience: "",
                              });
                            }}
                            // Apply red border if validation error exists
                            style={{
                              color: "black",
                              borderColor: validationErrors.experience ? "red" : "",
                            }}
                          />
                          {/* Display error message if validation error exists */}
                          {validationErrors.experience && (
                            <div className="error-text" style={{ color: "red" }}>
                              {validationErrors.experience}
                            </div>
                          )}
                        </FormGroup>
                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup className="mb-3">
                              <Label for="salaryFromInput" className="font-weight-bold">
                                Minimum Salary <span className="required-asterisk">*</span>
                              </Label>
                              <Input
                                type="text"
                                id="salaryFromInput"
                                placeholder="Enter Minimum Salary"
                                value={newItem.salaryFrom}
                                onChange={(e) => {
                                  const enteredValue = e.target.value;
                                  setNewItem({
                                    ...newItem,
                                    salaryFrom: enteredValue,
                                  });
                                  // Clear validation error when the user starts typing
                                  setValidationErrors({
                                    ...validationErrors,
                                    salaryFrom: "",
                                  });
                                }}
                                // Apply red border if validation error exists
                                style={{
                                  color: "black",
                                  borderColor: validationErrors.salaryFrom ? "red" : "",
                                }}
                              />
                              {/* Display error message if validation error exists */}
                              {validationErrors.salaryFrom && (
                                <div className="error-text" style={{ color: "red" }}>
                                  {validationErrors.salaryFrom}
                                </div>
                              )}
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup className="mb-3">
                              <Label for="salaryToInput" className="font-weight-bold">
                                Maximum Salary <span className="required-asterisk">*</span>
                              </Label>
                              <Input
                                type="text"
                                id="salaryToInput"
                                placeholder="Enter Maximum Salary"
                                value={newItem.salaryTo}
                                onChange={(e) => {
                                  const enteredValue = e.target.value;
                                  setNewItem({
                                    ...newItem,
                                    salaryTo: enteredValue,
                                  });
                                  // Clear validation error when the user starts typing
                                  setValidationErrors({
                                    ...validationErrors,
                                    salaryTo: "",
                                  });
                                }}
                                // Apply red border if validation error exists
                                style={{
                                  color: "black",
                                  borderColor: validationErrors.salaryTo ? "red" : "",
                                }}
                              />
                              {/* Display error message if validation error exists */}
                              {validationErrors.salaryTo && (
                                <div className="error-text" style={{ color: "red" }}>
                                  {validationErrors.salaryTo}
                                </div>
                              )}
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup className="mb-3">
                              <Label for="roleInput" className="font-weight-bold">
                                Job Mode <span className="required-asterisk">*</span>
                              </Label>
                              <Select
                                name="role"
                                id="roleInput"
                                value={newItem.mode ? modeOption.find((option) => option.code === newItem.mode) : null}
                                options={modeOption}
                                onChange={(selectedOption) => {
                                  setNewItem({ ...newItem, mode: selectedOption.code });
                                  setselectedMode(selectedOption);
                                  setValidationErrors({ ...validationErrors, mode: "" });
                                }}
                                placeholder="Select Mode"
                              />
                              {validationErrors.mode && (
                                <div className="error-text" style={{ color: "red" }}>
                                  {validationErrors.mode}
                                </div>
                              )}
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup className="mb-3">
                              <Label for="roleInput" className="font-weight-bold">
                                Job Type <span className="required-asterisk">*</span>
                              </Label>
                              <Select
                                options={fulltimeOptions}
                                value={fulltimeOptions.find((option) => option.value === newItem.fulltime)}
                                onChange={handleFulltimeChange}
                                placeholder="Select Job Type"
                              />
                              {validationErrors.fulltime && (
                                <div className="error-text" style={{ color: "red" }}>
                                  {validationErrors.fulltime}
                                </div>
                              )}
                            </FormGroup>
                          </div>
                        </div>
                        <Row>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <Label for="genderInput" className="font-weight-bold">
                                Gender Preferred <span className="required-asterisk">*</span>
                              </Label>
                              <Select
                                name="gender"
                                id="genderInput"
                                value={newItem.gender ? genders.find(option => option.value === newItem.gender) : null}   // Find the selected option based on newItem.gender
                                onChange={(selectedOption) => handleGenderChange(selectedOption)}
                                options={genders}
                                placeholder="Select Gender"
                              />
                              {validationErrors.gender && (
                                <div className="error-text" style={{ color: "red" }}>
                                  {validationErrors.gender}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <Label for="dateInput" className="font-weight-bold">
                                Scheduled Date <span className="required-asterisk">*</span>
                              </Label>
                              <Input
                                type="date"
                                name="date"
                                id="dateInput"
                                value={newItem.schdate ? newItem.schdate : null}
                                onChange={handleDateChange}
                                min={today}
                                style={{ color: "black", }}
                              />
                              {validationErrors.schdate && (
                                <div className="error-text" style={{ color: "red" }}>
                                  {validationErrors.schdate}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup className="mb-3">
                          <Label for="statusInput" className="font-weight-bold">
                            Status <span className="required-asterisk">*</span>
                          </Label>
                          <Select
                            name="status"
                            id="statusInput"
                            value={statuses.find((option) => option.value === newItem.status)}
                            onChange={handleStatusChange}
                            options={statuses}
                            placeholder="Select Status"
                          />
                          {/* Display error message if validation error exists */}
                          {validationErrors.status && (
                            <div className="error-text" style={{ color: 'red' }}>
                              {validationErrors.status}
                            </div>
                          )}
                        </FormGroup>

                      </div>

                    </Form>

                  </div>
                  <div className="jobpost-button">
                    <Button onClick={handleSave} disabled={loading} variant="success">
                      Update
                    </Button>
                    <Button variant="danger" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </div>
                </div>
              </div>

            )}
            <div className="table-container">
              <table className="modern-table">
                <thead>
                  <tr>
                    {selectedColumnsData.map(
                      (col) =>
                        col.display && (
                          <th
                            key={col.Header}
                            style={{
                              width: `${100 / selectedColumnsData.length}%`,
                            }}
                          >
                            {col.Header}
                          </th>
                        )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item) => (
                    <tr
                      key={item.id}
                      className={`${selectedItemId === item.id ? "selected" : ""
                        }`}
                      onClick={() => handleRowClick(item.id)}
                    >
                      {selectedColumnsData.map((col) => (
                        <td
                          key={col.name}
                          style={{
                            width: `${100 / selectedColumnsData.length}%`,
                          }}
                        >
                          {col.name === "logo" ? (
                            <div
                              style={{
                                width: "50px",
                                height: "50px",
                                overflow: "hidden",
                                borderRadius: "50%",
                                border: "1px solid #ddd",
                              }}
                            >
                              <img
                                src={`data:image/jpeg;base64,${item.logo}`}
                                alt="Preview"
                                style={{
                                  maxWidth: "100%",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                          ) : (
                            item[col.name]
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div style={{ padding: "10px" }}>
              <div style={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ padding: "10px" }}>
                    <span>Show : </span>
                    <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                    </select>
                  </div>
                  <div className="pagination" style={{ margin: "auto" }}>
                    <button
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      &laquo;
                    </button>

                    {[1, 2, 3].map((page) => (
                      <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={currentPage === page ? "active" : ""}
                      >
                        {page}
                      </button>
                    ))}

                    <button
                      disabled={currentPage === Math.ceil(data.length / rowsPerPage)}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      &raquo;
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <MySnackbar
              snackbarOpen={snackbarOpen}
              handleSnackbarClose={handleSnackbarClose}
              snackbarMessage={snackbarMessage}
              snackbarBgColor={snackbarBgColor}
              isSuccess={isSuccess}
            />
            <ToastContainer />

          </div>
        </div>
      </div>
    </>
  );
};
export default OrganisationTable;
