import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useAuth } from "views/examples/AuthContext";
import { useApiUrl } from "views/examples/ApiUrlContext";

const Header = () => {
  const { userData } = useAuth();
  const { apiUrl } = useApiUrl();
  const { userLoginCode } = useAuth();
  const [jobSeekers, setJobSeekers] = useState(0);
  const [activeListings, setActiveListings] = useState(0);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [activeAlerts, setActiveAlerts] = useState(0);


  useEffect(() => {
    const bearerToken = userData.accessToken;
    axios.get(`${apiUrl}/iBasic/dbdata/${userLoginCode.name}`,

      {
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json', // Adjust the content type if needed
        },
      })
      .then(response => {
        // Update state with the fetched data
        setJobSeekers(response.data.jobapplications);
        setActiveListings(response.data.jobposts);
        setTotalEmployees(response.data.employees);
        setActiveAlerts(response.data.i_alerts);
      })
      .catch(error => {

      });
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <>
      <div className="pb-8 pt-5">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0" 
                style={{ 
                  height: '150px', 
                  width: '100%', 
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow
                  border: '2px solid #e3e6f0', // Add border
                  borderRadius: '8px' // Optional: round the corners
                }}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 dashboard-card-title-white"
                        >
                          Total Job Seekers
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {jobSeekers}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <Link to="/admin/myjobs">
                          <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                            <i className="fas fa-user" />
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0" 
                 style={{ 
                  height: '150px', 
                  width: '100%', 
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow
                  border: '2px solid #e3e6f0', // Add border
                  borderRadius: '8px' // Optional: round the corners
                }}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Active Job Listings
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{activeListings}</span>
                      </div>
                      <Col className="col-auto">
                        <Link to="/admin/Recruitment">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-briefcase" />
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0" 
                 style={{ 
                  height: '150px', 
                  width: '100%', 
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow
                  border: '2px solid #e3e6f0', // Add border
                  borderRadius: '8px' // Optional: round the corners
                }}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Employees
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{totalEmployees}</span>
                      </div>
                      <Col className="col-auto">
                        <Link to="/admin/Employee">
                          <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                            <i className="fas fa-file-alt" />
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0" 
                style={{ 
                  height: '150px', 
                  width: '100%', 
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow
                  border: '2px solid #e3e6f0', // Add border
                  borderRadius: '8px' // Optional: round the corners
                }}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                          style={{ color: 'white' }}
                        >
                          Active Alerts
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{activeAlerts}</span>
                      </div>
                      <Col className="col-auto">
                        <Link to="/admin/Notification">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-building" />
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
