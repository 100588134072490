// Util.js
import { useApiUrl } from "views/examples/ApiUrlContext";
import { useAuth } from "views/examples/AuthContext";
import axios from 'axios';


const useUtil = () => {
  const { apiUrl } = useApiUrl();
  const { userData } = useAuth();

  const refreshToken = async () => {
    try {
      const bearerToken = userData.token;
      const url = `${apiUrl}/token/refreshToken`;
      const refreshResponse = await axios.post(
        url,
        {
            bearerToken
        },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );
      if (refreshResponse.status === 200) {
        const responseBody = await refreshResponse.json();
        const accessToken = responseBody.accessToken;
  
        if (accessToken && accessToken.length > 0) {
          // Save the new access token
          localStorage.setItem('accessToken', accessToken);
          return accessToken;
        }
      }
  
      return 'new_access_token';
    } catch (error) {
      return 'new_access_token';
    }
  };
  
  const logout = () => {
    // Perform logout actions
    localStorage.clear();
    window.location.href = "/auth/login";
  };

  return { refreshToken, logout };
};

export default useUtil;
