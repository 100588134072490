import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { AuthProvider } from "views/examples/AuthContext"; 
import { ApiUrlProvider } from "views/examples/ApiUrlContext"; 
import { Sharedvars } from "views/examples/Sharedvars"
import LandingPage from "views/examples/Introduction"
import BlogPost from "views/examples/BlogPost"

// Get the root container
const container = document.getElementById("root");

// Create a root
const root = createRoot(container);

// Initial render
root.render(
  <BrowserRouter>
    <AuthProvider>
      <ApiUrlProvider>
        <Sharedvars>
          <Routes>       
            <Route path="/admin/*" element={<AdminLayout />} />
            <Route path="/auth/*" element={<AuthLayout />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/blog/:id" element={<BlogPost />} />
            <Route path="*" element={<Navigate to="/auth/login" replace />} />
          </Routes>
        </Sharedvars>
      </ApiUrlProvider>
    </AuthProvider>
  </BrowserRouter>
);

// Add Google Tag Manager script
const gtmScript = document.createElement("script");
gtmScript.async = true;
gtmScript.src = "https://www.googletagmanager.com/gtag/js?id=G-KYN4KK1C02";
document.head.appendChild(gtmScript);
