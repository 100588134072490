// LettersPage.js
import React, { useState, useEffect } from "react";
import axios from 'axios';
import Select from "react-select";
import AppointmentLetter from "views/examples/AppointmentLetterTemplate";
import { useAuth } from "views/examples/AuthContext";
import { useApiUrl } from "views/examples/ApiUrlContext";
import { useNavigate } from 'react-router-dom';
import useUtil from "views/examples/Util"
import Topbar from "../examples/TopBar"
const Letters = () => {
  const { userData } = useAuth();
  const { apiUrl } = useApiUrl();
  const { instbasic } = useAuth();
  const { userLoginCode } = useAuth();
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !userData || 
      userData === undefined || 
      userData === '' || 
      (typeof userData === 'object' && Object.keys(userData).length === 0)
    ) {
      navigate('/auth/login'); 
    }
  }, [userData, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bearerToken = userData.accessToken;
        const response = await axios.get(`${apiUrl}/employees/icode/${userLoginCode.name}`, {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json', // Adjust the content type if needed
          },
        });
  
        const options = response.data.map(employee => ({
          value: employee.employeeId,
          label: employee.name
        }));
        setDropdownOptions(options);
        setFaculties(response.data);
        
      } catch (error) {
        console.error('Error fetching employee details:', error);
      }
    };
  
    fetchData(); 
  
    // Ensure to include any dependencies if needed here
  }, [userData.accessToken, userLoginCode.name]); // Add dependencies if fetchData relies on them
  

  const handleEmployeechange = (selectedOption) => {
    setSelectedEmployee(selectedOption.value);
   

  };


  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh", margin: "5px" }}
    >
    <Topbar />

    <div style={{ marginTop: '20px' }}>
        <Select
          options={dropdownOptions}
          placeholder="Select Employee"
          value={dropdownOptions.find(option => option.value === selectedEmployee)}
          onChange={handleEmployeechange}
          id="letterDropdown"
        />
      </div>
      <AppointmentLetter
       employeeId={selectedEmployee}
       Faculties={faculties}
        />
    </div>
  );
};

export default Letters;
